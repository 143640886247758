<div class="modal-dialog" *ngIf="!showConsent">
  <div class="modal-content">
    <div class="modal-header align-items-start">
      <div class="flex-grow-1 text-center">
        <h4 class="modal-title">{{ entry.product.name }}</h4>
      </div>
      <button (click)="closeSubscriptionModal()" aria-label="Close" class="close" type="button">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="modal-body text-center">
      <label class="w500" [for]="'frequency'+entry.product.code">Frequency</label>
      <div class="form-group mt-1 mb-1" *ngIf="entry.isEditable">
        <select [id]="'frequency'+entry.product.code" name="frequency" class="form-select custom-select"
          [ngValue]="entry.frequency.selected || DeliveryFrequencies.Week1"
          (change)="onFrequencyChanged($event.target.value);">
          <option *ngFor="
                            let frequency of entry.frequency.available
                              | toDeliveryFrequency
                          " [value]="frequency.code"
            [selected]="entry.frequency.selected === frequency.code || DeliveryFrequencies.Week1 === frequency.code ? true : false">
            {{ frequency.code | appSubscriptionFrequency }}
          </option>
        </select>
      </div>

      <!-- <label class="w500 pt-3" for="duration"
        *ngIf="entry.isEditable && entry.duration.available  && !isOneTimeShipment">Length</label>
      <div class="form-group mt-1 mb-2" *ngIf="entry.isEditable  && !isOneTimeShipment">
        <select id="duration" name="duration" class="form-control custom-select"
          [value]="entry.duration.selected || DeliveryFrequencies.Week1"
          (change)="onDurationChanged($event.target.value);">
          <option *ngFor="
                            let duration of entry.duration.available
                              | toDeliveryDuration
                          " [value]="duration.code">{{ duration.name }}</option>
        </select>
      </div> -->
      <!-- <div class="mt-4 d-flex align-items-center justify-content-center">
                    <span class="w500 me-3">Total Price: </span>
                    <h4 class="m-0">{{ entry.totalPrice.formattedValue}}</h4>
                </div> -->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
        (click)="onSubmit(entry);">
        DONE
      </button>
    </div>
  </div>
</div>


<div class="modal-dialog" *ngIf="showConsent">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Subscribe for Autoship</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeSubscriptionModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body consent-modal">
      <p>By selecting “Add to Cart”, you agree to our <a href="https://www.colgatepalmolive.co.in/legal-privacy-policy" target="_blank">Privacy Policy</a>, <a href="/terms" target="_blank">Terms of Service</a>, and/or <a href="/support-faq/subscriptions" target="_blank">Autoship Policy</a>.</p>
    <p>One or more items in your cart is a deferred or recurring purchase. By proceeding, you permit us to automatically charge your card, at the purchase price (plus shipping charges and applicable taxes) and subscription frequency indicated on your cart. Charges to your card may increase after the applicable trial period. The payment method may be updated in your shopping cart.</p>
    <p>Your subscription will continue until cancelled, and you may cancel your subscription or <a href="/support-faq/order" target="_blank">skip shipment</a> at any time under the <a href="/subscriptions" (click)="navigateToSubscriptions($event)">Subscriptions</a> tab or by contacting us. For partial cancellation, applicable charges may be incurred. All cancellations are subject to the <a href="/support-faq/order" target="_blank">cancellation policy</a>.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onDontWantToSubscribe()">I Don’t Want to
        Subscribe</button>
      <button type="button" class="btn btn-primary" (click)="onAcknowledge()">Acknowledge</button>
    </div>
  </div>
</div>