import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AddingCart, AutoShipTemplate, Facet, NutritionPlan,
  Product, SearchedProducts, Pet
} from '../../../api';
import { ProductFilters, ProductFiltersSort } from '../../models/productFilters';

@Component({
  selector: 'app-np-products',
  templateUrl: './np-products.component.html',
  styleUrls: ['./np-products.component.scss']
})
export class NpProductsComponent implements OnInit, OnChanges {

  shopForm: UntypedFormGroup;

  @Input() clinicId: string | null;

  @Input() changeProduct: boolean | false;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() sort: string | null;

  @Input() searchedProducts: SearchedProducts | null;

  @Input() product: Product | null;

  @Input() nutritionPlan: NutritionPlan | null;

  @Input() nutritionPlans: NutritionPlan[] | null;

  @Input() facets: Facet[] | null;

  @Input() filters: ProductFilters;

  @Output() submitted = new EventEmitter<ProductFiltersSort>();

  @Output() filterProductsSubmitted = new EventEmitter<ProductFiltersSort>();

  @Output() adding = new EventEmitter<AddingCart>();

  @Input() addingPending: boolean | false;

  @Input() subscription: AutoShipTemplate | null;

  @Input() subscriptionChangePending: boolean | false;

  @Input() pet: Pet | null;

  @Input() showWellnessProducts: boolean | false;

  @Input() wellnessId: string | null;

  @Input() wellnessPlan;

  @Output() subscriptionProductChanged = new EventEmitter<string>();

  @Output() searchWellnessProducts = new EventEmitter<string>();

  @Output() searchWellnessProductsByName = new EventEmitter();

  @Output() seeMoreProducts = new EventEmitter();

  @Output() navigatedBack = new EventEmitter();

  @ViewChild('successModal', { static: true })
  successModal: any;

  @Input()
  filtersSorted = [];

  @ViewChild('productChangedModal', { static: true })
  productChangedModal: any;

  href: string = '';
  petFlag: boolean = false;
  showSortPage: boolean = false;
  showFilterPage: boolean = false;
  showSortOptions: boolean = false;
  name: string = '';
  windowRef: any;
  private viewItemListFired = false;

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router) {
      this.windowRef = window;
    this.createForm();
  }

  ngOnInit() {

    this.href = this.router.url;
    if (this.href.indexOf('np-products-change') !== -1) {
      this.petFlag = false;
    } else {
      this.petFlag = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchedProducts'] && changes['searchedProducts'].currentValue) {
      this.viewedItemList = false; // reset the flag when the list changes
    }
    // This logic structure is written this way to ensure that no errors are thrown if a property does not exist on another property.
    // This structures function is to clear the search bar when a user changes the NP.
    if (
      changes["nutritionPlan"] && 
      changes["nutritionPlan"]["currentValue"] &&
      changes["nutritionPlan"]["previousValue"] &&
      changes["nutritionPlan"]["currentValue"]["brands"] &&
      changes["nutritionPlan"]["previousValue"]["brands"] &&
      changes["nutritionPlan"]["currentValue"]["brands"]["length"] !== changes["nutritionPlan"]["previousValue"]["brands"]["length"]
    ) {
      this.name = "";
    }

    const wellnessIdChange: SimpleChange = changes['wellnessId'];
    if (wellnessIdChange && wellnessIdChange.firstChange) {
      this.showWellnessProducts = wellnessIdChange.currentValue === '0' ? true : false;
    }

    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.shopForm.disable();
      } else {
        this.shopForm.enable();
      }
    }

    const sortChange: SimpleChange = changes['sort'];
    if (sortChange && sortChange.currentValue !== null) {
      this.resetForm();
    }

    const addingPendingChange: SimpleChange = changes['addingPending'];
    if (addingPendingChange && !addingPendingChange.firstChange) {
      if (!addingPendingChange.currentValue) {
        this.addingSuccess();
      }
    }

    const subscriptionChangePendingChange: SimpleChange = changes['subscriptionChangePending'];
    if (subscriptionChangePendingChange && !subscriptionChangePendingChange.firstChange) {
      if (!subscriptionChangePendingChange.currentValue) {
        this.productChangedSuccess();
      }
    }
    this.updateViewItemList(); 
  }

  private viewedItemList = false;

  updateViewItemList() {
    if (this.searchedProducts?.products?.length > 0 && !this.viewedItemList) {
      let dataLayer = this.windowRef['dataLayer'] || [];
      let products = this.searchedProducts?.products?.map((entry, index) => ({
        item_id: entry.code,
        item_name: entry.name, 
        index: index,
        discount: (entry.msrpPrice - entry.discountedPrice).toFixed(2), //discount on unit price of product/item, set to 0 if not applied
        quantity: 1,
        price: entry.msrpPrice
      })) || [];
      
      dataLayer.push({
          event: "view_item_list",
          ecommerce: { items: products }
      });
      this.viewedItemList = true;
    }
  }

  createForm() {
    this.shopForm = this.fb.group({
      sort: '',
      name: ''
    });
  }

  resetForm() {
    this.shopForm.reset({
      sort: this.sort
    });
  }

  search(page: number = 0, filters: ProductFilters = null) {
    if (this.shopForm.valid) {
      const model = this.shopForm.value;
      const sort = model.sort;
      this.filtersSorted = this.filters.appliedFilter;
      this.submitted.emit({ sort: sort, filters: filters, page: page });
      this.name = ''; // resetting searched bar field value
    }
  }

  filterProducts(page: number = 0, filters: ProductFilters = null) {
    if (this.shopForm.valid) {
      const model = this.shopForm.value;
      const sort = model.sort;
      this.filtersSorted = this.filters.appliedFilter;
      this.filterProductsSubmitted.emit({ sort: sort, filters: filters, page: page });
      this.name = ''; // resetting searched bar field value
    }
  }

  private _searchWellnessProducts() {
    this.searchWellnessProducts.emit(this.wellnessPlan[0].id);
  }

  selectProduct(product: Product) {
    this.product = product;
    if (this.shopForm.valid) {
      this.subscriptionProductChanged.emit(product.code);
    }
  }

  addingSuccess() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate(['/cart']);
        },
        () => {
        }
      );
    });
  }

  productChangedSuccess() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.productChangedModal).result.then(
        () => {
          this.router.navigate(['/subscriptions']);
          //this.router.navigate(['/subscriptions', this.subscription.subscriptionId]);
        },
        () => {
          this.router.navigate(['/subscriptions']);
          //this.router.navigate(['/subscriptions', this.subscription.subscriptionId]);
        }
      );
    });
  }

  showSortPopup() {
    this.showSortPage = !this.showSortPage;
  }

  toggleSortOptions() {
    this.showSortOptions = !this.showSortOptions;
  }
  showFilterPopup() {
    this.showFilterPage = !this.showFilterPage;
  }

 loadMoreProducts() {
    let name = this.name;
    this.seeMoreProducts.emit(name);
    this.updateViewItemList();
  }

  private _searchWellnessProductsByName(name?) {
    this.name = name;
    this.searchWellnessProductsByName.emit(this.name);
  }

  goBack() {
    this.navigatedBack.emit();
    // this.router.navigate([this.back]);
  }

  showProductDetail(clinicId : string, productCode : string, petCode : string, product: Product, msrpPrice: number, discountedPrice: number, i ) {
    let dataLayer = this.windowRef['dataLayer'] || [];
    dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
          {
          item_id: productCode,
          item_name: product.name, 
          discount: (msrpPrice - discountedPrice).toFixed(2),
          quantity: 1,
          price:msrpPrice.toFixed(2),
          index: i
        }
      ]
    }
  });
    this.router.navigate(["/np-product", clinicId, productCode, petCode])
  }

  showProductDetailForChangeProduct(clinicId : string, productCode : string, petCode : string) {
    this.router.navigate(["/np-product-change", clinicId, productCode, petCode])
  }
}
