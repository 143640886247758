import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { LoginPageComponent } from './containers/login-page/login-page.component';
import { RegisterPageComponent } from './containers/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './containers/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './containers/reset-password-page/reset-password-page.component';

import { AuthEffects } from './effects/auth.effects';
import { reducers } from './reducers';

import { AuthGuard } from './guards/auth.guard';
import { CheckRegisteredGuard } from './guards/check-registered.guard';
import { CheckTokenGuard } from './guards/check-token.guard';

import { RegisterPetParentComponent } from './components/register-pet-parent/register-pet-parent.component';
import { RegisterPetParentPageComponent } from './containers/register-pet-parent-page/register-pet-parent-page.component';
import { PetParentRegistrationSuccessComponent } from './components/register-pet-parent/pet-parent-registration-success/pet-parent-registration-success.component';


// End
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects])
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    LoginPageComponent,
    RegisterComponent,
    ResetPasswordComponent,
    RegisterPageComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    RegisterPetParentComponent,
    RegisterPetParentPageComponent,
    PetParentRegistrationSuccessComponent,
  ]
})
export class AuthModule {
  // Invisible V3 captcha for login -  
  static forRoot():  ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthGuard,
        CheckRegisteredGuard,
        CheckTokenGuard
      ],
    };
  }
  // End
}
