<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg custom-font pt-4 pb-4 profile-edit">
    <div class="container px-4">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center profile-edit-tile pt-2">
          <h5 class="w800 m-0">
            <a class="btn-back-arrow me-1" href="javascript:void(0)" (click)="goBack()" aria-label="Back">
              <i class="fa fa-arrow-left"></i>
            </a>
            {{pet?.petCode ? 'Edit' : 'Add'}} Profile</h5>
        </div>
      </div>
      <div class="d-flex flex-column mt-5" *ngIf="pet?.petCode">
        <figure>
          <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName}}" />
        </figure>
        <!-- <div class="d-flex justify-content-center pt-3">
           <h6 class="text-uppercase fw-normal">Name</h6>
          <h5>{{pet?.petName}}</h5>
        </div> -->
      </div>

      <form [formGroup]="petForm" autocomplete="off" class="mt-4" (ngSubmit)="save()">
        <div class="d-flex justify-content-center" *ngIf="!pet?.petCode">
          <div class="d-flex align-items-center py-5">
            <div class="select-pet mx-2">
              <input type="radio" id="petType_dog" class="custom-control-input" formControlName="petType"
                [value]="PetTypes.Dog">
              <label class="custom-control-label" for="petType_dog">
                <figure>
                  <img src="assets/images/dog.svg" class="app-pet-img" alt="Dog Image" />
                </figure>
                <span class="d-flex justify-content-center w500 pt-2">Dog</span>
              </label>
            </div>
          </div>

          <div class="d-flex align-items-center py-5">
            <div class="select-pet mx-2">
              <input type="radio" id="petType_cat" class="custom-control-input" formControlName="petType"
                [value]="PetTypes.Cat">
              <label class="custom-control-label" for="petType_cat">
                <figure>
                  <img src="assets/images/cat.svg" class="app-pet-img" alt="Cat Image" />
                </figure>
                <span class="d-flex justify-content-center w500 pt-2">Cat</span>
              </label>
            </div>
          </div>
        </div>
        <!-- <label for="name" class="w500">Name</label> -->
        <div class="form-group" [class.is-invalid]="petForm.controls['name'].dirty && petForm.controls['name'].invalid">
          <label for="name" class="sr-only">Name</label>
          <input id="name" name="name" type="text" class="form-control" placeholder="Name" formControlName="name"
            autofocus>
        </div>
        <!-- Save button disables with error message if name is greater than max limit - Rahul Solanki -->
        <ul
          *ngIf="petForm.controls['name'].errors && petForm.controls['name'].dirty"
          class="list-unstyled app-invalid-feedback alternate small">
          <li *ngIf="petForm.controls['name'].errors.whitespace">
            Should contain a valid value
          </li>
          <li *ngIf="petForm.controls['name'].errors.maxlength">
            You have exceeded the maximum character limit
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>
        <!-- END -->
        <div class="mt-2">
          <button type="submit" class="btn btn-blue py-3 w-100"
            [disabled]="petForm.pristine || petForm.invalid || petForm.disabled">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>




<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg custom-font pt-4 pb-5 profile-edit">
    <div class="container px-4">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center profile-edit-tile pt-2">
          <h5 class="w800 m-0">
            <a class="btn-back-arrow me-2" href="javascript:void(0)" (click)="goBack()" aria-label="Back">
              <i class="fa fa-arrow-left"></i>
            </a>{{pet?.petCode ? 'Edit' : 'Add'}} Profile</h5>
        </div>
      </div>
      <div class="d-flex flex-column mt-5" *ngIf="pet?.petCode">
        <figure>
          <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName}}" />
        </figure>
        <!-- <div class="d-flex justify-content-center pt-3">
           <h6 class="text-uppercase fw-normal">Name</h6>
          <h5>{{pet?.petName}}</h5>
        </div> -->
      </div>

      <form [formGroup]="petForm" autocomplete="off" class="mt-4" (ngSubmit)="save()">
        <div class="d-flex justify-content-center" *ngIf="!pet?.petCode">
          <div class="d-flex align-items-center py-5">
            <div class="select-pet mx-2">
              <input type="radio" id="petType_dog" class="custom-control-input" formControlName="petType"
                [value]="PetTypes.Dog">
              <label class="custom-control-label" for="petType_dog">
                <figure>
                  <img src="assets/images/dog.svg" class="app-pet-img" alt="Dog Image" />
                </figure>
                <span class="d-flex justify-content-center w500 pt-2">Dog</span>
              </label>
            </div>
          </div>

          <div class="d-flex align-items-center py-5">
            <div class="select-pet mx-2">
              <input type="radio" id="petType_cat" class="custom-control-input" formControlName="petType"
                [value]="PetTypes.Cat">
              <label class="custom-control-label" for="petType_cat">
                <figure>
                  <img src="assets/images/cat.svg" class="app-pet-img" alt="Cat Image" />
                </figure>
                <span class="d-flex justify-content-center w500 pt-2">Cat</span>
              </label>
            </div>
          </div>
        </div>
        <div class="name-field-container">
          <label for="name" class="w500">Name</label>
          <div class="form-group"
            [class.is-invalid]="petForm.controls['name'].dirty && petForm.controls['name'].invalid">
            <input id="name" name="name" type="text" class="form-control" placeholder="Name" formControlName="name"
              autofocus>
          </div>
          <!-- Save button disables with error message if name is greater than max limit - Rahul Solanki -->
          <ul
            *ngIf="petForm.controls['name'].errors && petForm.controls['name'].dirty "
            class="list-unstyled app-invalid-feedback alternate small">
            <li *ngIf="petForm.controls['name'].errors.whitespace">
              Should contain a valid value
            </li>
            <li *ngIf="petForm.controls['name'].errors.maxlength">
              You have exceeded the maximum character limit
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>
          <!-- END -->
          <div class="mt-2">
            <button type="submit" class="btn btn-blue py-3 w-100"
              [disabled]="petForm.pristine || petForm.invalid || petForm.disabled">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully {{pet?.petCode? 'changed your pet name' : 'added your pet'}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
