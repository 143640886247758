import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entryCount'
})
export class EntryCountPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    if (value != null && !isNaN(value)) { // check for both null/undefined and NaN
      return value > 0 ? (value > 1 ? `${value} items` : `1 item`) : 'No item';
    }

    return 'No item';
  }
}