<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
  <ng-template #mobileLayout>
    <div class="app-mobile-layout d-flex flex-column">
      <div class="header-banner">

        <div class="container d-flex align-items-start pt-4">
          <div class="home-logo">
            <img src="assets/images/060118_HillsToHomeLogo.png">
          </div>
          <button class="visit-btn mt-3" (click)="openUrl()">
            <span class="w700">VISIT HILLSPET.COM</span>
            <i class="fa fa-external-link-square-alt ps-2"></i>
          </button>
        </div>
      <div class="d-flex flex-column flex-grow-1 flex-shrink-0 justify-content-around align-items-center">
        <!-- <div class="d-flex align-items-center">
          <i class="app-logo"></i>
        </div> -->
        <div class="text-center font-weight-bold my-5 mx-2">
          Thank you for registering on Hill's to Home! <br>We just sent you an email to validate your address. Please check your inbox and click on the link in our email to activate your account.<br>
          If our email isn't there, please let us know by emailing our support team at <a class="text-underline" href="mailto:hillstohome@hillspet.com">hillstohome&#64;hillspet.com</a> or calling (800) 235-6877
        </div>
      </div>
      </div>
    </div>
  </ng-template>
  <ng-template #fullLayout>
    <div class="app-full-layout">
      <div class="header-banner">

        <div class="container d-flex align-items-start pt-4">
          <div class="home-logo">
            <img src="assets/images/060118_HillsToHomeLogo.png">
          </div>
          <button class="visit-btn mt-3" (click)="openUrl()">
            <span class="w700">VISIT HILLSPET.COM</span>
            <i class="fa fa-external-link-square-alt ps-2"></i>
          </button>
        </div>
      <div class="container pt-6 pb-6">
       
          <h5> Thank you for registering on Hill's to Home!<br><br>We just sent you an email to validate your address. Please check your inbox and click on the link in our email to activate your account.<br>
          If our email isn't there, please let us know by emailing our support team at <a class="text-underline" href="mailto:hillstohome@hillspet.com">hillstohome&#64;hillspet.com</a> or calling (800) 235-6877</h5>
       
      </div>
      </div>
    </div>
  </ng-template>


