/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LoginError } from '../model/loginError';
import { Session } from '../model/session';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

  
@Injectable()
export class AuthService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Check token validity
     *
     * @param token The token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the response.
     * @param reportProgress flag to report request and response progress.
     */
    public isTokenValid(token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public isTokenValid(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public isTokenValid(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public isTokenValid(token: string, observe: any = 'response', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling isTokenValid.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined) {
            queryParameters = queryParameters.set('token', <any>token);
            
        }

        let headers = this.defaultHeaders;

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.head<any>(`${this.basePath}/rest/v2/V2CSite/checkTokenValidity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs user into the system
     * 
     * @param username Credentials
     * @param password Credentials
     * @param grantType The grant type
     * @param clientId The client id
     * @param clientSecret The client secret
     * @param refreshToken The refresh token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(grantType: string, clientId: string, clientSecret: string, username: string, password: string, refreshToken?: string, observe?: 'body', reportProgress?: boolean): Observable<Session>;
    public login(grantType: string, clientId: string, clientSecret: string, username: string, password: string, refreshToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Session>>;
    public login(grantType: string, clientId: string, clientSecret: string, username: string, password: string, refreshToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Session>>;
    public login(grantType: string, clientId: string, clientSecret: string, username: string, password: string, refreshToken?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling login.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling login.');
        }

        if (grantType === null || grantType === undefined) {
            throw new Error('Required parameter grantType was null or undefined when calling login.');
        }

        if (clientId === null || clientId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling login.');
        }

        if (clientSecret === null || clientSecret === undefined) {
            throw new Error('Required parameter clientSecret was null or undefined when calling login.');
        }


        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (refreshToken !== undefined && refreshToken !== null) {
        //     queryParameters = queryParameters.set('refresh_token', <any>refreshToken);
        // }
        // if (grantType !== undefined && grantType !== null) {
        //     queryParameters = queryParameters.set('grant_type', <any>grantType);
        // }
        // if (clientId !== undefined && clientId !== null) {
        //     queryParameters = queryParameters.set('client_id', <any>clientId);
        // }
        // if (clientSecret !== undefined && clientSecret !== null) {
        //     queryParameters = queryParameters.set('client_secret', <any>clientSecret);
        // }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }
        if (refreshToken !== undefined && refreshToken !== null) {
            formParams = formParams.append('refresh_token', <any>refreshToken) || formParams;
        }
        if (grantType !== undefined && grantType !== null) {
            formParams = formParams.append('grant_type', <any>grantType) || formParams;
        }
        if (clientId !== undefined && clientId !== null) {
            formParams = formParams.append('client_id', <any>clientId) || formParams;
        }
        if (clientSecret !== undefined && clientSecret !== null) {
            formParams = formParams.append('client_secret', <any>clientSecret) || formParams;
        }
        if (username !== undefined) {
            formParams = formParams.append('username', <any>username) || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) || formParams;
        }

        return this.httpClient.post<Session>(`${this.basePath}/authorizationserver/oauth/token`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                //params: queryParameters,
                withCredentials: false, // This is made false to restrict cookie being sent as requested by Hybris Team
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Getpk
     * 
     * @param username The username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public getuserpk(username: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
       public getuserpk(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
       public getuserpk(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
       public getuserpk(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
           if (username === null || username === undefined) {
               throw new Error('Required parameter username was null or undefined when calling addPet.');
           }
   
           let headers = this.defaultHeaders;
   
           //to determine the Accept header
           let httpHeaderAccepts: string[] = [
               'application/json'
           ];
           let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set("Accept", httpHeaderAcceptSelected);
           }
   
           // to determine the Content-Type header
           let consumes: string[] = [
               'application/json'
           ];
           let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
           if (httpContentTypeSelected != undefined) {
               headers = headers.set("Content-Type", httpContentTypeSelected);
           }
           // Pass CSRF Token for invalidation in header parameter
           if (this.configuration.apiKeys["csrf_token"]) {
               headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
           }
   
           if (this.configuration.apiKeys["access_token"]) {
               headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
           }
           const requestBody = {
            uid: username
            };
           const pk = sessionStorage.getItem('pk');
           return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/getUserPk`,
           requestBody,
               {
                   withCredentials: this.configuration.withCredentialsAndCookies,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }

    /**
     * Reset password
     *
     * @param userId The user name for sending reset password link
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling resetPassword.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (userId !== undefined) {
        //     queryParameters = queryParameters.set('userId', <any>userId);
        // }
        const requestBody = {
            uid: userId
        };

        let headers = this.defaultHeaders;

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/forgottenpasswordtokens`,
        requestBody,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set password
     *
     * @param token The token
     * @param newPassword The new password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPassword(token: string, newPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPassword(token: string, newPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPassword(token: string, newPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPassword(token: string, newPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling setPassword.');
        }
        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling setPassword.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (newPassword !== undefined) {
        //     queryParameters = queryParameters.set('newPassword', <any>newPassword);
        // }

        // if (token !== undefined) {
        //     queryParameters = queryParameters.set('token', <any>token);
        // }

        let headers = this.defaultHeaders;

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
         // Create a request body object
        let requestBody = {
            password: newPassword,
            token: token
         };

        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/setPassword`,
        requestBody,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Register pet parent
     *
     * @param petParentDetail Pet Parent detail for new registration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerPetParent(petParentDetail: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public registerPetParent(petParentDetail: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public registerPetParent(petParentDetail: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public registerPetParent(petParentDetail: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (petParentDetail !== undefined) {
            queryParameters = petParentDetail;
        }

        let headers = this.defaultHeaders;

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/resgisterUsers/registerPetParent`,
        petParentDetail,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Logout
     * 
     * @param username The username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userLogout(username: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userLogout(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userLogout(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userLogout(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addPet.');
        }

        let headers = this.defaultHeaders;

        //to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        // Pass CSRF Token for invalidation in header parameter
        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }
        
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (this.configuration.apiKeys["access_token"] !== undefined) {
            queryParameters = queryParameters.set('access_token', this.configuration.apiKeys["access_token"]);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/logout`,
        null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get CSRF Token
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCSRFToken(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getCSRFToken(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getCSRFToken(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getCSRFToken(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.get<any>(`${this.basePath}/rest/v2/V2CSite/fetchtoken`,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
     // Invisible captcha for login
    /**
     * Get Site Verify Captcha 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public GetSiteVerifyCaptcha(captchaToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public GetSiteVerifyCaptcha(captchaToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public GetSiteVerifyCaptcha(captchaToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public GetSiteVerifyCaptcha(captchaToken?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let headers = this.defaultHeaders;
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }
        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        if (captchaToken === null || captchaToken === undefined) {
            throw new Error('Required parameter captchaToken was null or undefined when calling GetSiteVerifyCaptcha.');
        }
        const body = {
            token: captchaToken
        };
        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/fetchRecaptcha`, body, {
            withCredentials: this.configuration.withCredentialsAndCookies,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    // End 
    
     // End
}
