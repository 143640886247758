import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { SupportRoutingModule } from './support-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { SupportEffects } from './effects/support.effects';
import { SupportComponent } from './components/support/support.component';
import { SupportPageComponent } from './containers/support-page/support-page.component';
import { SupportFaqComponent } from './components/support-faq/support-faq.component';
import { FaqPageComponent } from './containers/faq-page/faq-page.component';
import { FaqContentComponent } from './components/faq-content/faq-content.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContactUsPageComponent } from './containers/contact-us-page/contact-us-page.component';
import { ContactUsResponseComponent } from './components/contact-us/contact-us-response/contact-us-response.component';

@NgModule({
  imports: [
    NgbAccordionModule,
    CommonModule,
    SupportRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature('support', reducers),
    EffectsModule.forFeature([SupportEffects]),
  ],
  declarations: [SupportPageComponent,
    SupportComponent,
    SupportFaqComponent,
    FaqPageComponent,
    FaqContentComponent,
    ContactUsComponent,
    ContactUsPageComponent,
    ContactUsResponseComponent
    ]
})
export class SupportModule {
  static forRoot(): ModuleWithProviders<SupportModule> {
    return {
      ngModule: SupportModule
    };
  }
}
