import { CorrectedAddress } from './../models/correctedAddress';
import { Action } from '@ngrx/store';
import {
  Profile,
  Payment,
  Address,
  ProfileAddresses
} from '../../api';
import { ProfilePayments } from '../models/ProfilePayments';
import { EmailChange } from '../models/emailChange';
import { NameChange } from '../models/nameChange';
import { PasswordChange } from '../models/passwordChange';
import { PhoneChange } from '../models/phoneChange';
import { ProfileChange } from '../models/profileChange';

export enum ProfileActionTypes {
  Load = '[Profile] Load',
  NewAddress = '[Profile] New Address',
  GetAddress = '[Profile] Get Address',
  GetAddressSuccess = '[Profile] Get Address Success',
  GetAddresses = '[Profile] Get Addresses',
  GetAddressesSuccess = '[Profile] Get Addresses Success',
  NewPayment = '[Profile] New Payment',
  GetPayment = '[Profile] Get Payment',
  GetPaymentSuccess = '[Profile] Get Payment Success',
  GetPayments = '[Profile] Get Payments',
  GetPaymentsSuccess = '[Profile] Get Payments Success',
  CheckAddress = '[Profile] Check Address',
  CheckAddressSuccess = '[Profile] Check Address Success',
  CheckAddressFailure = '[Profile] Check Address Failure',
  AddAddress = '[Profile] Add Address',
  AddAddressSuccess = '[Profile] Add Address Success',
  AddAddressReset = '[Profile] Add Address Reset',
  AddPayment = '[Profile] Add Payment',
  AddPaymentSuccess = '[Profile] Add Payment Success',
  AddPaymentFailure = '[Profile] Add Payment Failure',
  AddPaymentReset = '[Profile] Add Payment Reset',
  UpdateAddress = '[Profile] Update Address',
  UpdateAddressSuccess = '[Profile] Update Address Success',
  UpdateEmail = '[Profile] Update Email',
  UpdateEmailSuccess = '[Profile] Update Email Success',
  UpdateEmailFailure = '[Profile] Update Email Failure',
  UpdateEmailReset = '[Profile] Update Email Reset',
  UpdateProfile = '[Profile] Update Profile',
  UpdateProfileSuccess = '[Profile] Update Profile Success',
  UpdateName = '[Profile] Update Name',
  UpdateNameSuccess = '[Profile] Update Name Success',
  UpdatePassword = '[Profile] Update Password',
  UpdatePasswordSuccess = '[Profile] Update Password Success',
  UpdatePasswordFailure = '[Profile] Update Password Failure',
  UpdatePasswordReset = '[Profile] Update Password Reset',
  UpdateIsPasswordSuccessfullyUpdated = '[Profile] Update Is Password Successfully Updated',
  UpdatePayment = '[Profile] Update Payment',
  UpdatePaymentSuccess = '[Profile] Update Payment Success',
  UpdatePhone = '[Profile] Update Phone',
  UpdatePhoneSuccess = '[Profile] Update Phone Success',
  UpdateOptedIn = '[Profile] Update Opted In',
  UpdateOptedInSuccess = '[Profile] Update Opted In Success',
  UpdateTermsAcceptedStatus = '[Profile] Update Terms Accepted',
  UpdateTermsAcceptedStatusSuccess = '[Profile] Update Terms Accepted Success',
  DeleteAddress = '[Profile] Delete Address',
  DeleteAddressSuccess = '[Profile] Delete Address Success',
  DeletePayment = '[Profile] Delete Payment',
  DeletePaymentSuccess = '[Profile] Delete Payment Success',
  SetAddressPrimary = '[Profile] Set Address Primary',
  SetAddressPrimarySuccess = '[Profile] Set Address Primary Success',
  SetPaymentPrimary = '[Profile] Set Payment Primary',
  SetPaymentPrimarySuccess = '[Profile] Set Payment Primary Success',
}

export class Load implements Action {
  readonly type = ProfileActionTypes.Load;

  constructor(public profile: Profile) {
  }
}

export class NewAddress implements Action {
  readonly type = ProfileActionTypes.NewAddress;

  constructor() {
  }
}

export class NewPayment implements Action {
  readonly type = ProfileActionTypes.NewPayment;

  constructor() {
  }
}

export class GetAddress implements Action {
  readonly type = ProfileActionTypes.GetAddress;

  constructor(public addressId: string) {
  }
}

export class GetAddressSuccess implements Action {
  readonly type = ProfileActionTypes.GetAddressSuccess;

  constructor(public address: Address) {
  }
}

export class GetAddresses implements Action {
  readonly type = ProfileActionTypes.GetAddresses;

  constructor() {
  }
}

export class GetAddressesSuccess implements Action {
  readonly type = ProfileActionTypes.GetAddressesSuccess;

  constructor(public addresses: ProfileAddresses) {
  }
}

export class GetPayment implements Action {
  readonly type = ProfileActionTypes.GetPayment;

  constructor(public paymentId: string) {
  }
}

export class GetPaymentSuccess implements Action {
  readonly type = ProfileActionTypes.GetPaymentSuccess;

  constructor(public payment: Payment) {
  }
}

export class GetPayments implements Action {
  readonly type = ProfileActionTypes.GetPayments;

  constructor() {
  }
}

export class GetPaymentsSuccess implements Action {
  readonly type = ProfileActionTypes.GetPaymentsSuccess;

  constructor(public payments: ProfilePayments) {
  }
}
export class CheckAddress implements Action {
  readonly type = ProfileActionTypes.CheckAddress;

  constructor(public address: Address) {
  }
}
export class CheckAddressSuccess implements Action {
  readonly type = ProfileActionTypes.CheckAddressSuccess;

  constructor(public address: Address = null) {
  }
}
export class CheckAddressFailure implements Action {
	readonly type = ProfileActionTypes.CheckAddressFailure;

	constructor(public updateAddressError: boolean = false, public correctedAddress : CorrectedAddress = null) {}
}
export class AddAddress implements Action {
  readonly type = ProfileActionTypes.AddAddress;

  constructor(public address: Address) {
  }
}

export class AddAddressSuccess implements Action {
  readonly type = ProfileActionTypes.AddAddressSuccess;

  constructor(public address: Address = null) {
  }
}

export class AddAddressReset implements Action {
  readonly type = ProfileActionTypes.AddAddressReset;

  constructor() {
  }
}

export class AddPayment implements Action {
  readonly type = ProfileActionTypes.AddPayment;

  constructor(public tokenId: string) {
  }
}

export class AddPaymentSuccess implements Action {
  readonly type = ProfileActionTypes.AddPaymentSuccess;

  constructor(public paymentId: string) {
  }
}

export class AddPaymentFailure implements Action {
  readonly type = ProfileActionTypes.AddPaymentFailure;

  constructor(public addPaymentError: string) {
  }
}

export class AddPaymentReset implements Action {
  readonly type = ProfileActionTypes.AddPaymentReset;

  constructor() {
  }
}

export class UpdateAddress implements Action {
  readonly type = ProfileActionTypes.UpdateAddress;

  constructor(public addressId: string, public address: Address) {
  }
}

export class UpdateAddressSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateAddressSuccess;

  constructor() {
  }
}

export class UpdateEmail implements Action {
  readonly type = ProfileActionTypes.UpdateEmail;

  constructor(public emailChange: EmailChange) {
  }
}

export class UpdateEmailSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateEmailSuccess;

  constructor(public emailChange: EmailChange) {
  }
}

export class UpdateEmailFailure implements Action {
  readonly type = ProfileActionTypes.UpdateEmailFailure;

  constructor(public updateEmailError: string) {
  }
}

export class UpdateEmailReset implements Action {
  readonly type = ProfileActionTypes.UpdateEmailReset;

  constructor() {
  }
}

export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.UpdateProfile;

  constructor(public profileChange: ProfileChange) {
  }
}

export class UpdateProfileSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateProfileSuccess;

  constructor(public profileChange: ProfileChange) {
  }
}

export class UpdateName implements Action {
  readonly type = ProfileActionTypes.UpdateName;

  constructor(public nameChange: NameChange) {
  }
}

export class UpdateNameSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateNameSuccess;

  constructor() {
  }
}

export class UpdatePassword implements Action {
  readonly type = ProfileActionTypes.UpdatePassword;

  constructor(public passwordChange: PasswordChange) {
  }
}

export class UpdateIsPasswordSuccessfullyUpdated implements Action {
  readonly type = ProfileActionTypes.UpdateIsPasswordSuccessfullyUpdated

  constructor(public payload: boolean) {}

}

export class UpdatePasswordSuccess implements Action {
  readonly type = ProfileActionTypes.UpdatePasswordSuccess;

  constructor() {
  }
}

export class UpdatePasswordFailure implements Action {
  readonly type = ProfileActionTypes.UpdatePasswordFailure;

  constructor(public updatePasswordError: string) {
  }
}

export class UpdatePasswordReset implements Action {
  readonly type = ProfileActionTypes.UpdatePasswordReset;

  constructor() {
  }
}

export class UpdatePayment implements Action {
  readonly type = ProfileActionTypes.UpdatePayment;

  constructor(public paymentId: string, public payment: Payment) {
  }
}

export class UpdatePaymentSuccess implements Action {
  readonly type = ProfileActionTypes.UpdatePaymentSuccess;

  constructor() {
  }
}

export class UpdatePhone implements Action {
  readonly type = ProfileActionTypes.UpdatePhone;

  constructor(public phoneChange: PhoneChange) {
  }
}

export class UpdatePhoneSuccess implements Action {
  readonly type = ProfileActionTypes.UpdatePhoneSuccess;

  constructor() {
  }
}

export class UpdateOptedIn implements Action {
  readonly type = ProfileActionTypes.UpdateOptedIn;

  constructor(public optedIn: boolean) {
  }
}

export class UpdateOptedInSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateOptedInSuccess;

  constructor() {
  }
}

export class UpdateTermsAcceptedStatus implements Action {
  readonly type = ProfileActionTypes.UpdateTermsAcceptedStatus;

  constructor(public termsAndConditions: boolean) {
  }
}

export class UpdateTermsAcceptedStatusSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateTermsAcceptedStatusSuccess;

  constructor(public termsAndConditions: boolean) {
  }
}

export class DeleteAddress implements Action {
  readonly type = ProfileActionTypes.DeleteAddress;

  constructor(public addressId: string) {
  }
}

export class DeleteAddressSuccess implements Action {
  readonly type = ProfileActionTypes.DeleteAddressSuccess;

  constructor() {
  }
}

export class DeletePayment implements Action {
  readonly type = ProfileActionTypes.DeletePayment;

  constructor(public paymentId: string) {
  }
}

export class DeletePaymentSuccess implements Action {
  readonly type = ProfileActionTypes.DeletePaymentSuccess;

  constructor() {
  }
}

export class SetAddressPrimary implements Action {
  readonly type = ProfileActionTypes.SetAddressPrimary;

  constructor(public addressId: string) {
  }
}

export class SetAddressPrimarySuccess implements Action {
  readonly type = ProfileActionTypes.SetAddressPrimarySuccess;

  constructor() {
  }
}

export class SetPaymentPrimary implements Action {
  readonly type = ProfileActionTypes.SetPaymentPrimary;

  constructor(public paymentId: string) {
  }
}

export class SetPaymentPrimarySuccess implements Action {
  readonly type = ProfileActionTypes.SetPaymentPrimarySuccess;

  constructor(public primaryPayment: Payment) {
  }
}

export type ProfileActionsUnion =
  | Load
  | NewAddress
  | NewPayment
  | GetAddress
  | GetAddressSuccess
  | GetAddresses
  | GetAddressesSuccess
  | GetPayment
  | GetPaymentSuccess
  | GetPayments
  | GetPaymentsSuccess
  | CheckAddress
  | CheckAddressSuccess
  | CheckAddressFailure
  | AddAddress
  | AddAddressSuccess
  | AddAddressReset
  | AddPayment
  | AddPaymentSuccess
  | AddPaymentFailure
  | AddPaymentReset
  | UpdateAddress
  | UpdateAddressSuccess
  | UpdateEmail
  | UpdateEmailSuccess
  | UpdateEmailFailure
  | UpdateEmailReset
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateName
  | UpdateNameSuccess
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordFailure
  | UpdateIsPasswordSuccessfullyUpdated
  | UpdatePasswordReset
  | UpdatePayment
  | UpdatePaymentSuccess
  | UpdatePhone
  | UpdatePhoneSuccess
  | UpdateOptedIn
  | UpdateOptedInSuccess
  | UpdateTermsAcceptedStatus
  | UpdateTermsAcceptedStatusSuccess
  | DeleteAddress
  | DeleteAddressSuccess
  | DeletePayment
  | DeletePaymentSuccess
  | SetAddressPrimary
  | SetAddressPrimarySuccess
  | SetPaymentPrimary
  | SetPaymentPrimarySuccess;
