import { Action } from '@ngrx/store';
import { BreakpointState } from '@angular/cdk/layout';
import { LayoutModes } from '../constants/layout';
import { ScrollChange } from '../models/scrollChange';

export enum LayoutActionTypes {
  OpenSidenav = '[Layout] Open Sidenav',
  CloseSidenav = '[Layout] Close Sidenav',
  ChangeMedia = '[Layout] Change Media',
  ChangeLayoutMode = '[Layout] Change Layout',
  ChangeScroll = '[Layout] Change Scroll',
}

export class OpenSidenav implements Action {
  readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = LayoutActionTypes.CloseSidenav;
}

export class ChangeMedia implements Action {
  readonly type = LayoutActionTypes.ChangeMedia;

  constructor(public state: BreakpointState) { }
}

export class ChangeLayoutMode implements Action {
  readonly type = LayoutActionTypes.ChangeLayoutMode;

  constructor(public layoutMode: LayoutModes) { }
}

export class ChangeScroll implements Action {
  readonly type = LayoutActionTypes.ChangeScroll;

  constructor(public scrollChange: ScrollChange) { }
}

export type LayoutActionsUnion =
  | OpenSidenav
  | CloseSidenav
  | ChangeMedia
  | ChangeLayoutMode
  | ChangeScroll
  ;
