import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { supportList } from '../../../shared/constants/faq';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss'],
})
export class FaqContentComponent implements OnInit {

  @Input() isMobile: boolean | false;
  @Input() selectedFaq: supportList;
  @Input() isLoggedIn: boolean | false;
  @Input() whatsNewContent: any;
  
  @ViewChild('creditCardHolds', { static: true }) creditCardHoldsModal: any;

  @ViewChild('petProfilePanel') petProfilePanel: any;
  @ViewChild('subscriptionsPanel') subscriptionsPanel: any;

  selectedPanelId: string;
  
  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      this.selectedPanelId = null;
    });
  }

  ngOnInit(): void {}

  goToShopPlanFAQPage() { 
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/pet-profile']) : this.router.navigate(['/support-faq/pet-profile']);
    setTimeout(() => {
      this.toggleAccordionPanel('petProfilePanel_2');
      this.petProfilePanel.toggle('petProfilePanel_2');
    }, 500);
  }

  goToReturnsFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/returns-refunds']) : this.router.navigate(['/support-faq/returns-refunds']);
  }

  goToSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
  }

  goToChangeSubscriptionFrequencyFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.toggleAccordionPanel('subscriptionsPanel_2');
      this.subscriptionsPanel.toggle('subscriptionsPanel_2');
    }, 500);
  }

  goToCancelSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.toggleAccordionPanel('subscriptionsPanel_1');
      this.subscriptionsPanel.toggle('subscriptionsPanel_1')
    }, 500);
  }

  goToNewSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.toggleAccordionPanel('subscriptionsPanel_0');
      this.subscriptionsPanel.toggle('subscriptionsPanel_0')
    }, 500);
  }

  goToMyAccountPage() {
    this.isLoggedIn ? this.router.navigate(['/profile']) : this.router.navigate(['/login']);
  }
  
  goToMyPetsPage() {
    this.isLoggedIn ? this.router.navigate(['/pets/profile']) : this.router.navigate(['/login']);
  }

  goToShopPage() {
    this.isLoggedIn ? this.router.navigate(['/pets/shop']) : this.router.navigate(['/login']);
  }

  goToOrdersPage() {
    this.isLoggedIn ? this.router.navigate(['/orders']) : this.router.navigate(['/login']);
  }

  goToSubscriptionsPage() {
    this.isLoggedIn ? this.router.navigate(['/subscriptions']) : this.router.navigate(['/login']);
  }

  goToForgetPasswordPage() {
    this.router.navigate(['/forgot']);
  }

  goToContactUsPage() {
    this.router.navigate(['/contact-us']);
  }

  goToCreditCardHoldsPage() {
    setTimeout(() => {
      this.modalService.open(this.creditCardHoldsModal).result.then(
        () => {},
        () => {}
      );
    });
  }

  toggleAccordionPanel(panelId){
    this.selectedPanelId=this.selectedPanelId === panelId?null:panelId;
  }

}