<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
  <ng-template #mobileLayout>
    <div class="app-mobile-layout flex-column">
      <div class="header-banner">
        <div *ngIf="!isLoggedIn">
          <div class="container d-flex align-items-start pt-4">
            <div class="home-logo">
              <img src="assets/images/060118_HillsToHomeLogo.png" (click)="gotoLogin()">
            </div>
            <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
              <span class="w700">Sign In</span>
              <i class="fa ps-2"></i>
            </button>
            <!-- <button class="visit-btn mt-3" (click)="openUrl()">
              <span class="w700">VISIT HILLSPET.COM</span>
              <i class="fa fa-external-link-square-alt ps-2"></i>
            </button> -->
          </div>
      </div>
      <!-- <div class="d-flex flex-column flex-grow-1 flex-shrink-0 justify-content-around align-items-center"> -->
        <div class="text-center font-weight-light mt-6 mb-6" *ngIf="success; else showError">
          <h3 class="text-primary">Thank you for Contacting us!</h3>
          <div>
            <h6>We're looking forward to helping as quickly as we can. Stay tuned.</h6>
          </div>
        </div>
        <ng-template #showError>
          <div class="text-center font-weight-light mt-6 mb-6">
             <h3 class="text-primary">Sorry! Something went wrong.</h3>
           <div>
             <h6>Do you want us to call instead?</h6><br>
             <a href="tel:+1-800-235-6877"
             target="_top"
             class="text-underline">
             1-800-235-6877
             </a><br>Monday-Friday 7am - 7pm CST
           </div>
         </div>
        </ng-template>
      <!-- </div> -->
    </div>
  </div>
  </ng-template>
  <ng-template #fullLayout>
    <div class="app-full-layout">
      <div class="header-banner">
        <div *ngIf="!isLoggedIn">
          <div class="container d-flex align-items-start pt-4">
          <div class="home-logo" (click)="gotoLogin()">
            <img src="assets/images/060118_HillsToHomeLogo.png">
          </div>
            <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
              <span class="w700">Sign In</span>
              <i class="fa ps-2"></i>
            </button>
            <!-- <button class="visit-btn mt-3" (click)="openUrl()">
              <span class="w700">VISIT HILLSPET.COM</span>
              <i class="fa fa-external-link-square-alt ps-2"></i>
            </button> -->
          </div>
        </div>
      <div class="container pt-6 pb-6">
       <div class="text-center font-weight-light" *ngIf="success; else showError">
          <h3 class="text-primary">Thank you for Contacting us!</h3>
          <div>
            <h6>We're looking forward to helping as quickly as we can. Stay tuned.</h6>
          </div>
       </div>
       <ng-template #showError>
         <div class="text-center font-weight-light">
            <h3 class="text-primary">Sorry! Something went wrong.</h3>
          <div>
            <h6>Do you want us to call instead?</h6><br>
            <a href="tel:+1-800-235-6877"
            target="_top"
            class="text-underline">
            1-800-235-6877
            </a><br>Monday-Friday 7am - 7pm CST
          </div>
        </div>
       </ng-template>
      </div>
    </div>
    </div>
  </ng-template>



