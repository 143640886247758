import { Component, Input, OnInit } from '@angular/core';
import {
  OrderDetail
} from '../../../api';
import { DeliveryFrequencies, LabelTypes } from '../../../shared/constants/payment';
import { orderDetail } from './place-order';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {

  DeliveryFrequencies = DeliveryFrequencies;

  LabelTypes = LabelTypes;

  @Input() isMobile: boolean | false;

  @Input() placedOrder: OrderDetail | null;

  windowRef: any; 

  constructor() {
    this.windowRef = window;
  }

  ngOnInit() {
    this.setGTMForOrderConfirmation();
  }

  removeDollarSign(formattedValue : string) {
    return formattedValue.replace(/\$|,/g, "");
  }

  mapPetType(petType: string): string {
    switch (petType.toUpperCase()) {
      case "CANINE":
        return "Dog";
      case "FELINE":
        return "Cat";
    }
    return petType;
  }

  setGTMForOrderConfirmation()
  {
    if(this.placedOrder.code) {
      let dataLayer = this.windowRef['dataLayer'] || [];
      let shippingProducts = this.placedOrder.entries.map((entry, index) => ({
        item_id: entry.product.code,
        item_name: entry.product.name, 
        index: index,
        discount: (entry.basePrice.value - entry.totalPrice.value / entry.quantity ).toFixed(2),
        quantity: entry.quantity,
        price: entry.basePrice.value.toFixed(2)
      }));
    
      if (shippingProducts !== undefined) {
        dataLayer.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: this.placedOrder.deliveryCost.currencyIso,
            value: this.placedOrder.subTotal.value.toFixed(2),
            shipping_tier: this.placedOrder.deliveryMode.name,
            items: shippingProducts
          }
        });
      }
      dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          currency: this.placedOrder.deliveryCost.currencyIso,
          value: this.placedOrder.subTotal.value.toFixed(2),
          payment_type: this.placedOrder.paymentInfo.cardType.code,
          items: shippingProducts
        }
      });
    
      const ga4Tag = {
        event: 'purchase',
        ecommerce: {
          transaction_id: this.placedOrder.code,
          total_value: parseFloat(this.placedOrder.totalPrice.value.toFixed(2)),
          value: this.placedOrder.subTotal.value.toFixed(2),
          tax: parseFloat(this.placedOrder.totalTax.value.toFixed(2)),
          shipping: this.placedOrder.deliveryCost.value,
          currency: this.placedOrder.totalPrice.currencyIso,
          total_surcharge: parseFloat(this.placedOrder.totalSurcharge.value.toFixed(2)),
          coupon: this.placedOrder.appliedVouchers ? this.placedOrder.appliedVouchers[0].code : '',
          order_discount: parseFloat(this.placedOrder.productDiscounts.value.toFixed(2)),
          retailDeliveryFee: parseFloat(this.placedOrder.retailDeliveryFee.value.toFixed(2)),
          items: this.placedOrder.entries.map(entry => ({
            item_id: entry.product.code,
            item_name: entry.product.name,
            coupon: this.placedOrder.appliedVouchers ? this.placedOrder.appliedVouchers[0].code : '',
            discount: parseFloat((entry.basePrice.value - (entry.totalPrice.value / entry.quantity)).toFixed(2)),
            price: entry.basePrice.value,
            quantity: entry.quantity,
            item_variant: entry.product.baseOptions[0].selected.size
          }))
        }
      };
      dataLayer.push(ga4Tag);
    }
  }

}
