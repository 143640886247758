<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout fixed-top fixed-bottom d-flex flex-column h-100">
    <div class="d-flex align-self-center h-30">
      <h2 class="align-self-center text-white">Page Not Found!</h2>
    </div>
    <div class="flex-grow-1 m-4">
      <a routerLink="/"
         class="btn btn-primary w-100 text-white">
        Go Home
      </a>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5 text-center">
      <h2>Page Not Found!</h2>
      <a routerLink="/"
         class="btn btn-primary ps-5 pe-5 btn-lg m-5">
        Go Home
      </a>
    </div>
  </div>
</ng-template>






