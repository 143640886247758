<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <div class="pt-6 pb-6 mt-6">
      <h2 class="app-title alternate text-center m-3 p-3"
          *ngIf="profile">
        Welcome {{profile.firstName}}
      </h2>
    </div>
    <div class="app-placeholder pt-3">
      <h3 class="fw-normal text-center ps-3 pe-3 pt-3">
        <span class="app-title">Introducing Hill's to Home:</span>
      </h3>
      <h5 class="fw-normal text-center ps-5 pe-5">
        <span class="text-muted">
          #1 Vet Recommended brand brought right to your door for free
        </span>
      </h5>
      <hr class="mt-3"/>
      <h4 class="fw-normal mt-4 p-3">Pets</h4>

      <div class="d-flex flex-wrap justify-content-center p-3" *ngIf="profile?.pets?.length">
        <a [routerLink]="['/pets', pet.petCode]"
           *ngFor="let pet of profile.pets; let i = index;">
          <div class="border p-3 m-2 app-title">
            <img src="{{pet | appPetImg}}"
                 class="app-pet-img"
                 alt="{{pet.petName}}" />
          </div>
          <div class="text-center">
            {{pet.petName}}
          </div>
        </a>
      </div>
      <h4 class="fw-normal mt-4 p-3">Orders</h4>
      <div class="p-3">
        <table class="table">
          <thead>
          <tr>
            <th>Order Number</th>
            <th>Date Placed</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let order of orders" (click)="select(order)">
            <td class="text-underline">{{order.code}}</td>
            <td>{{order.placed | date: 'MM/dd/yyyy'}}</td>
            <td class="app-status">{{order.status | appOrderStatus}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5">
      <div class="d-flex pt-5 pb-6">
        <div class="flex-grow-1 d-flex flex-column align-self-center">
        </div>
        <div class="d-flex w-30">
          <i class="app-logo"></i>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container pt-5 pb-5">
        <h2 class="font-weight-light p-4 d-flex flex-column align-items-center">
          <span class="app-title">Introducing Hill's to Home:</span>
          <span class="mt-2">#1 Vet Recommended brand</span>
          <span class="mt-2">brought right to your door for free</span>
        </h2>
        <hr class="mt-5" />
        <div class="d-flex mt-5">
          <div class="w-50 p-4">
            <h4 class="fw-normal">Pets</h4>
            <div class="border mt-4" *ngIf="profile && profile?.pets?.length">
              <a [routerLink]="['/pets', pet.petCode]"
                 *ngFor="let pet of profile.pets; let i = index;"
                 class="d-flex"
                 [class.border-bottom]="i !== (profile.pets.length - 1)">
                <div class="border p-3 m-4">
                  <img src="{{pet | appPetImg}}"
                       class="app-pet-img-sml"
                       alt="{{pet.petName}}"/>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <div>Name</div>
                  <b>{{pet.petName}}</b>
                </div>
              </a>
            </div>
          </div>
          <div class="w-50 p-4">
            <h4 class="fw-normal">Orders</h4>
            <div class="border mt-4" *ngIf="orders && orders.length">
              <table class="table">
                <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Date Placed</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let order of orders" (click)="select(order)">
                  <td class="text-underline">{{order.code}}</td>
                  <td>{{order.placed | date: 'MM/dd/yyyy'}}</td>
                  <td class="app-status">{{order.status | appOrderStatus}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-title alternate p-5 d-flex flex-column align-items-center">
      <h2 class="text-white font-weight-light">Questions? Comments? Concerns?</h2>
      <h5 class="text-white font-weight-light mt-3">
        Drop an email to
        <a href="mailto:hillstohome@hillspet.com?Subject=Hello%20Again!"
           target="_top"
           class="text-white text-underline">
          hillstohome&#64;hillspet.com
        </a>
        or give us a call at
        <a href="tel:+1-800-235-6877"
           target="_top"
           class="text-white text-underline">
          1-800-235-6877
        </a>
        -- we'd love to hear from you!
      </h5>
    </div>
  </div>
</ng-template>
