import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Authenticate } from "../../models/authenticate";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Patterns } from "src/app/shared/validation/patterns";
import { AuthService } from "src/app/api";
import { environment } from "src/environments/environment";

declare var turnstile: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnChanges {
  authForm: UntypedFormGroup;
 
  @Input() pending: boolean | false;
  @Input() errorMessage: string | null;
  @Input() isMobile: boolean | false;
  @Input() isTokenExpired: boolean | false;
  @Input() isMaintenanceMode: boolean | false;
  @Input() isVisible: boolean | false;
  @Output() submitted = new EventEmitter<Authenticate>();
  @Output() resolvedEvent = new EventEmitter<String>();

  @ViewChild("resetPwdFailedModal", { static: true }) resetPwdFailedModal: any;

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private authService: AuthService  // Inject the AuthService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.showPasswordResetDialog();
    this.loadTurnstileScript();
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes["pending"];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.authForm.disable();
      } else {
        this.authForm.enable();
      }
    }

  }

  createForm() {
    this.authForm = this.fb.group({
      username: [
        "",
        [Validators.required, Validators.pattern(Patterns.emailPattern)],
      ],
      password: ["", Validators.required],
    });
  }
  // captcha for login

  loadTurnstileScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.renderTurnstile();
    };

    this.renderer.appendChild(document.body, script);
  }

  renderTurnstile() {
    if (typeof turnstile !== 'undefined') {
      turnstile.render('#turnstile-container', {
        sitekey: environment.turnstile_site_key,
        theme: 'light',
        callback: (token: string) => {
          console.log(`Challenge Success ${token}`);
          this.resolved(token);
        }
      });
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: \${captchaResponse}`);
    this.errorMessage=null;
    this.resolvedEvent.emit(captchaResponse);
  }

  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }
  // End
  login() {
    if (this.authForm.valid) {
      const model = this.authForm.value;
      const authenticate: Authenticate = {
        username: model.username.toLowerCase(),
        password: model.password,
      };
      const turnstileResponse = (window as any).turnstile.getResponse();
      if (turnstileResponse) {
        this.authService.GetSiteVerifyCaptcha(turnstileResponse).subscribe(response => {
          if (response && response.success) {
            alert('Verification successful!');
            this.submitted.emit(authenticate);
          } else {
            alert('Verification failed. Please try again.');
          }
        },
          error => {
            console.error('Verification service error:', error);
            alert('There was an error verifying the captcha. Please try again.');
          });
      } else {
        console.log('verification required')
        this.errorMessage="Captcha Verification is required";
        
      }
    }
  }

  showPasswordResetDialog() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      if (this.isTokenExpired) {
        this.modalService.open(this.resetPwdFailedModal, {
          centered: true,
          size: "sm",
        });
      }
    });
  }

  //Toggle Password Visibility

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  openUrl() {
    window.open("https://www.hillspet.com/", "_blank");
  }
}
