<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      Edit Subscription
    </h5>
    <div class="d-flex align-items-center m-4">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">#{{subscription?.subscriptionId}}</h6>
      </div>
    </div>
    <form [formGroup]="orderForm" autocomplete="off" (ngSubmit)="save()">
      <div class="d-flex p-3 ms-3 me-3" *ngFor="let entry of subscription?.cart?.entries; let i=index"
        [class.border-bottom]="i!==(subscription?.cart?.entries.length - 1)">
        <div class="d-flex flex-column align-items-center">
          <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
          <div class="app-cart-img text-center" *ngIf="!entry.product.images">
            <i class="fas fa-image fa-4x"></i>
          </div>
          <div class="text-black-50 small mt-3">
            SKU {{entry.product.code}}
          </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column ms-2">
          <div>
            {{entry.product.name}}
          </div>
          <div class="mt-2">
            <div class="font-weight-bold">Quantity:</div>
            <div class="product-qty d-flex align-items-center pt-2 pb-2">
              <div class="qty-field d-flex">
                <button type="button" (click)="onQuantityRemoved()">
                  <strong>-</strong>
                </button>
                <!-- class="d-flex align-items-center justify-content-center" -->
                <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
                <input type="text" [id]="'quantity'+entry.product.code" formControlName="quantity" readonly />
                <button type="button" (click)="onQuantityAdded()">
                  +
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="font-weight-bold">Subscription</div>
            <div class="small app-title">Saving 5%</div>
            <div class="form-group mt-1 mb-1" *ngIf="subscription.availableDeliveryFrequencies !== undefined">
              <label [for]="'frequency_'+i" class="small">Frequency</label>
              <select [id]="'frequency_'+i" [name]="'frequency_'+i" class="form-control custom-select"
                [value]="frequencyValue(subscription)" formControlName="frequency">
                <option *ngFor="let frequency of subscription.availableDeliveryFrequencies"
                  [ngValue]="frequency | uppercase">
                  {{frequency | appSubscriptionFrequency}}
                </option>
              </select>
            </div>
            <!-- <div class="font-weight-bold"
              *ngIf="entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime && subscription.availableDuration !== undefined">
              for
            </div>

            <div class="form-group mt-1 mb-0"
              *ngIf="entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime && subscription.availableDuration !== undefined">
              <label [for]="'duration_'+i" class="small">Length</label>
              <select [id]="'duration_'+i" [name]="'duration_'+i" class="form-control custom-select"
                [value]="durationValue(subscription)" formControlName="duration">
                <option *ngFor="let duration of displayDuration(subscription)" [value]="duration">
                  {{duration | appSubscriptionLength}}
                </option>
              </select>
            </div> -->
          </div>
          <div class="mt-2"
            *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
            <span class="font-weight-bold">Price: </span>
            <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
            <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
          </div>
          <div class="mt-2">
            <span class="font-weight-bold">Pet: </span>
            <span>{{entry.pet}}</span>
          </div>
          <div class="mt-2"
            *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
            <span class="font-weight-bold">Price: </span>
            <span>{{entry.basePrice.formattedValue}}</span>
          </div>
          <div class="mt-2">
            <span class="font-weight-bold">Subtotal: </span>
            <span>{{subscription?.cart?.totalPrice.formattedValue}}</span>
          </div>
          <div class="mt-2">
            <div class="font-weight-bold">Next Estimated Delivery Date</div>
            <div class="form-group mt-1 mb-0"
              [class.is-invalid]="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].invalid">
              <label [for]="'deliveryDate_'+i" class="sr-only">Delivery Date</label>
              <input [id]="'deliveryDate_'+i" type="date" class="form-control" formControlName="deliveryDate"
                min="{{minDate}}" max="9999-12-31">
            </div>
            <ul *ngIf="(orderForm.controls['frequency'].dirty && orderForm.controls['deliveryDate'].pristine)|| (orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors)"
            class="list-unstyled app-invalid-feedback small mt-2 mb-0">
              <li *ngIf="orderForm.controls['frequency'].dirty && orderForm.controls['deliveryDate'].pristine">
                Click here to change estimated next delivery date
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['onlyWeekDays']">
                Please select week days only
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['maxDateReached']">
                Please select a date before {{maxDateForDisplay | date : 'MM/dd/yyyy'}}
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['minDate']">
                Please select a date on or after {{minDate | date: 'MM/dd/yyyy'}}
              </li>
              <i class="app-bottom-arrow"></i>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-3">
        <hr class="mt-3" />
        <button class="btn btn-primary btn-lg font-weight-light w-100 mb-5"
          [disabled]="orderForm.pristine || orderForm.invalid || orderForm.disabled">
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <form [formGroup]="orderForm" autocomplete="off" (ngSubmit)="save()">
      <div class="d-flex p-4 bg-white text-body" *ngFor="let entry of subscription?.cart?.entries; let i=index"
        [class.border-bottom]="i!==(subscription?.cart?.entries.length - 1)">
        <div class="d-flex flex-column align-items-center">
          <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
          <div class="app-cart-img text-center" *ngIf="!entry.product.images">
            <i class="fas fa-image fa-4x"></i>
          </div>
          <div class="text-black-50 small mt-3">
            SKU {{entry.product.code}}
          </div>
        </div>
        <div class="flex-grow-1 ms-2">
          <div>
            {{entry.product.name}}
          </div>
          <div class="d-flex justify-content-between mt-3">
            <div class="d-flex align-items-center flex-grow-1">
              <div>
                <div class="font-weight-bold">Price</div>
                <div
                  *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                  <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                  <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                </div>
                <div
                  *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                  {{entry.basePrice.formattedValue}}
                </div>
              </div>
              <div class="ms-3">
                <div class="font-weight-bold">Subtotal</div>
                <span>{{subscription?.cart?.totalPrice.formattedValue}}</span>
              </div>
            </div>
            <div class="flex-column">
              <div class="font-weight-bold">Quantity</div>
              <div class="product-qty d-flex align-items-center">
                <div class="qty-field d-flex">
                  <button type="button" (click)="onQuantityRemoved()">
                    <strong>-</strong>
                  </button>
                  <!-- class="d-flex align-items-center justify-content-center" -->
                  <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
                  <input type="text" [id]="'quantity'+entry.product.code" formControlName="quantity" readonly />
                  <button type="button" (click)="onQuantityAdded()">
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-3">
          <div class="mt-2">
            <div class="font-weight-bold">Subscription</div>
            <div class="small app-title">Saving 5%</div>
            <div class="d-flex align-items-center">
              <div class="form-group mt-1 mb-1" *ngIf="subscription.availableDeliveryFrequencies !== undefined">
                <label [for]="'frequency_'+i" class="small">Frequency</label>
                <select [id]="'frequency_'+i" [name]="'frequency_'+i" class="form-control custom-select"
                  [value]="frequencyValue(subscription)" formControlName="frequency">
                  <option *ngFor="let frequency of subscription.availableDeliveryFrequencies"
                    [ngValue]="frequency | uppercase">
                    {{frequency | appSubscriptionFrequency}}
                  </option>
                </select>
              </div>
              <!-- <div class="font-weight-bold ms-2" *ngIf="entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime
                   && subscription.availableDuration !== undefined">
                for
              </div>
              <div class="form-group mt-1 mb-0 ms-2"
                *ngIf="entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime && subscription.availableDuration !== undefined">
                <label [for]="'duration_'+i" class="small">Length</label>
                <select [id]="'duration_'+i" [name]="'duration_'+i" class="form-control custom-select"
                  [value]="durationValue(subscription)" formControlName="duration">
                  <option *ngFor="let duration of displayDuration(subscription)" [value]="duration">
                    {{duration | appSubscriptionLength}}
                  </option>
                </select>
              </div> -->
            </div>
          </div>
          <hr class="mt-3">
          <div class="mt-2">
            <span class="font-weight-bold">Pet: </span>
            <span>{{entry.pet}}</span>
          </div>
          <div class="mt-2 d-flex flex-column align-items-start">
            <div class="font-weight-bold">Next Estimated Delivery Date</div>
            <div class="form-group mt-1 mb-0"
              [class.is-invalid]="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].invalid">
              <label [for]="'deliveryDate_'+i" class="sr-only">Delivery Date</label>
              <input [id]="'deliveryDate_'+i" type="date" class="form-control" formControlName="deliveryDate"
                min="{{minDate}}" max="9999-12-31">
            </div>
            <ul *ngIf="(orderForm.controls['frequency'].dirty && orderForm.controls['deliveryDate'].pristine)|| (orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors)"
            class="list-unstyled app-invalid-feedback small mt-2 mb-0">
              <li *ngIf="orderForm.controls['frequency'].dirty && orderForm.controls['deliveryDate'].pristine">
                Click here to change estimated next delivery date
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['onlyWeekDays']">
                Please select week days only
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['maxDateReached']">
                Please select a date before {{maxDateForDisplay | date : 'MM/dd/yyyy'}}
              </li>
              <li *ngIf="orderForm.controls['deliveryDate'].dirty && orderForm.controls['deliveryDate'].errors && orderForm.controls['deliveryDate'].errors['minDate']">
                Please select a date on or after {{minDate | date: 'MM/dd/yyyy'}}
              </li>
              <i class="app-bottom-arrow"></i>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!--Edit Subscription for Mobile Popup-->
<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your subscription
    </p>
    <p class="m-4 h3 text-center" *ngIf="orderForm.controls['deliveryDate'].value">
      Your estimated next delivery date is on {{orderForm.controls['deliveryDate'].value | date:'MM/dd/yyyy'}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
