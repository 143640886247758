<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">

    <h5 class="app-title text-center m-4">
      My Account
    </h5>

    <div class="d-flex align-items-center m-4">
      <h6 class="mb-0">Edit Email</h6>
    </div>

    <form [formGroup]="profileForm" autocomplete="off" class="m-4 edit-form" (ngSubmit)="save()">
      <div class="form-group"
           [class.is-invalid]="profileForm.controls['email'].dirty && profileForm.controls['email'].invalid">
        <label for="email" class="small">Current Email Address</label>
        <input id="email"
               name="email"
               type="email"
               class="form-control"
               placeholder="Email"
               formControlName="email">
      </div>
      <ul *ngIf="profileForm.controls['email'].dirty &&
               profileForm.controls['email'].errors &&
               profileForm.controls['email'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small px-2">
        <li *ngIf="profileForm.controls['email'].errors['pattern']">
          Email not valid
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
           [class.is-invalid]="profileForm.controls['newEmail'].dirty && profileForm.controls['newEmail'].invalid">
        <label for="newEmail" class="small">New Email Address</label>
        <input id="newEmail"
               name="newEmail"
               type="email"
               class="form-control"
               placeholder="Email"
               formControlName="newEmail">
      </div>
      <ul *ngIf="profileForm.controls['newEmail'].dirty &&
               profileForm.controls['newEmail'].errors &&
               profileForm.controls['newEmail'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small px-2">
        <li *ngIf="profileForm.controls['newEmail'].errors['pattern']">
          Email not valid
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
           [class.is-invalid]="profileForm.controls['confirmEmail'].dirty && profileForm.controls['confirmEmail'].invalid">
        <label for="confirmEmail" class="small">Confirm New Email Address</label>
        <input id="confirmEmail"
               name="confirmEmail"
               type="email"
               class="form-control"
               placeholder="Email"
               formControlName="confirmEmail">
      </div>
      <ul *ngIf="profileForm.controls['confirmEmail'].dirty &&
               profileForm.controls['confirmEmail'].errors &&
               profileForm.controls['confirmEmail'].errors['matchEmail']"
          class="list-unstyled app-invalid-feedback small px-2">
        <li *ngIf="profileForm.controls['confirmEmail'].errors['matchEmail']">
          Email does not match
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>

      <p class="pt-3 pb-3">
        To change your current email address, please enter your current password
      </p>

      <div class="form-group"
           [class.is-invalid]="profileForm.controls['password'].dirty && profileForm.controls['password'].invalid">
        <label for="password" class="small">Account Password</label>
        <input id="password"
               name="password"
               [type]="isVisible ? 'text' : 'password'" 
               class="form-control"
               placeholder="Password"
               formControlName="password"
               autocomplete="new-password">
        <span (click)="toggleVisibility()" [ngClass]="isVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <div *ngIf="error"
           class="alert alert-danger mt-4 small text-center">
        {{error}}
      </div>
      <div class="mt-5">
        <button type="submit"
                class="btn btn-primary w-100"
                [disabled]="profileForm.pristine || profileForm.invalid || profileForm.disabled">
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <form [formGroup]="profileForm" autocomplete="off" class="app-full-layout m-4 edit-form" (ngSubmit)="save()" (keyup.enter)="save()">
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['email'].dirty && profileForm.controls['email'].invalid">
      <label for="emailFullLayout" class="small">Current Email Address</label>
      <input id="emailFullLayout"
             name="email"
             type="email"
             class="form-control"
             placeholder="Email"
             formControlName="email">
    </div>
    <ul *ngIf="profileForm.controls['email'].dirty &&
               profileForm.controls['email'].errors &&
               profileForm.controls['email'].errors['pattern']"
        class="list-unstyled app-invalid-feedback alternate small px-2">
      <li *ngIf="profileForm.controls['email'].errors['pattern']">
        Email not valid
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['newEmail'].dirty && profileForm.controls['newEmail'].invalid">
      <label for="newEmail" class="small">New Email Address</label>
      <input id="newEmail"
             name="newEmail"
             type="email"
             class="form-control"
             placeholder="Email"
             formControlName="newEmail">
    </div>
    <ul *ngIf="profileForm.controls['newEmail'].dirty &&
               profileForm.controls['newEmail'].errors &&
               profileForm.controls['newEmail'].errors['pattern']"
        class="list-unstyled app-invalid-feedback alternate small px-2">
      <li *ngIf="profileForm.controls['newEmail'].errors['pattern']">
        Email not valid
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['confirmEmail'].dirty && profileForm.controls['confirmEmail'].invalid">
      <label for="confirmEmail" class="small">Confirm New Email Address</label>
      <input id="confirmEmail"
             name="confirmEmail"
             type="email"
             class="form-control"
             placeholder="Email"
             formControlName="confirmEmail">
    </div>
    <ul *ngIf="profileForm.controls['confirmEmail'].dirty &&
               profileForm.controls['confirmEmail'].errors &&
               profileForm.controls['confirmEmail'].errors['matchEmail']"
        class="list-unstyled app-invalid-feedback  alternate small px-2">
      <li *ngIf="profileForm.controls['confirmEmail'].errors['matchEmail']">
        Email does not match
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>

    <p class="pt-3 pb-3">
      To change your current email address, please enter your current password
    </p>

    <div class="form-group"
         [class.is-invalid]="profileForm.controls['password'].dirty && profileForm.controls['password'].invalid">
      <label for="passwordFullLayout" class="small">Account Password</label>
      <input id="passwordFullLayout"
             name="password"
             [type]="isVisible ? 'text' : 'password'" 
             class="form-control"
             placeholder="Password"
             formControlName="password"
             autocomplete="new-password">
      <span (click)="toggleVisibility()" [ngClass]="isVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
    </div>

    <div *ngIf="error"
         class="alert alert-danger mt-4 small text-center">
      {{error}}
    </div>
  </form>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully changed your email
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
            (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
