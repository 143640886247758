import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterContentChecked,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef
} from '@angular/core';
import { Cart, EditingCart, Entry, ProfileAddresses } from '../../../api';
import {
  deliveryDurations,
  DeliveryDurations,
  deliveryFrequencies,
  DeliveryFrequencies,
  LabelTypes
} from '../../../shared/constants/payment';
import { quantities } from '../../../shared/constants/shop';
import { CartChange } from '../../models/cartChange';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePayments } from 'src/app/profile/models/ProfilePayments';
import { FormGroup } from '@angular/forms';
import { ShopUtil } from '../../../shared/utils/shopUtil';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges {
  quantities = quantities;

  DeliveryFrequencies = DeliveryFrequencies;

  LabelTypes = LabelTypes;

  deliveryDurations = deliveryDurations.filter(
    dd => dd.code !== DeliveryDurations.Unlimited
  );

  deliveryFrequencies = deliveryFrequencies;

  @Input()
  isMobile: boolean | false;

  @Input()
  pending: boolean | false;

  @Input()
  cart: Cart | null;

  @Input() profileAddresses: ProfileAddresses | null;

  @Input() profilePayments: ProfilePayments | null;

  @Output()
  submitted = new EventEmitter<CartChange>();

  @Output()
  submitOrder = new EventEmitter();

  entryModel: Array<any> = [];

  totalItems: number = 0;

  isOutOFStockCartItems: boolean = false;

  @ViewChild('successModal', { static: true })
  successModal: any;

  @ViewChild('confirmDeleteCartItemModal', { static: true })
  confirmDeleteCartItemModal: any;

  selectedIndex = null;

  // @ViewChild('cartInfoModal')
  // cartInfoModal: any;

  @ViewChildren('cartInfoModal') cartInfoModal: QueryList<ElementRef>;
  entryTotalPrice: string;
  isOneTimeShipment: boolean = false;

  isCheckoutProductsTagged = false;       //Google Analytics tag Products only once

  windowRef: any;
  ga4TagRemovefromcarttag: boolean = false;

  constructor(private router: Router, private modalService: NgbModal) { 
    this.windowRef = window;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    const cartChange: SimpleChange = changes['cart'];
    //console.log("order summary : " + JSON.stringify(cartChange));
    if (cartChange && !cartChange.firstChange) {
      if (cartChange.currentValue) {
        this.totalItems = cartChange.currentValue.deliveryItemsQuantity;
        this.isOutOFStockCartItems = cartChange.currentValue.isOutOFStockCartItems;  //checks out of stock products in cart
        //navigate back to home page when there are no items in cart
        if (this.totalItems === 0) {
          this.success();
        }
        //By Default maximum count of dropdown is 99
        let quantityNum: number = 99;
        if (this.totalItems > quantityNum) {
          let newQuantity = this.getQuantitiesFn(this.totalItems);
          this.quantities = newQuantity;
        } else {
          this.quantities = quantities;
        }

        this.entryModel = new Array<any>();
        if (cartChange.currentValue.entries !== undefined) {
          // cartChange.currentValue.entries[0]['subscriptionUnit']={"availableDeliveryFrequencies":["ONETIME","Week_2","Week_3","Week_4","Week_5","Week_6","Week_7","Week_8"],"availableDuration":["MONTH_1","MONTH_2","MONTH_3","MONTH_4","MONTH_5","MONTH_6","MONTH_7","MONTH_8","MONTH_9","MONTH_10","MONTH_11"],"brands":[{"code":"PrescriptionDiet","description":"Prescription Diet","name":"Prescription Diet"}],"deliveryFrequency":"WEEK_4","duration":"MONTH_11","endDate":"Wed May 27 03:14:02 EDT 2020","isPrescriptionDiet":true,"nutritionPlanId":"8796846730021","productLines":[{"code":"K/D","description":"k/d base","name":"k/d� Kidney Care"}],"species":"Dog"};
          cartChange.currentValue.entries.forEach(entry => {

            if (entry.subscriptionUnit) {
              this.entryModel.push({
                ...entry,
                isEditable: entry.subscriptionUnit.availableDeliveryFrequencies ? true : false,
                frequency: {
                  selected: entry.subscriptionUnit.deliveryFrequency,
                  available: entry.subscriptionUnit.availableDeliveryFrequencies,
                  isOneTime: entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime
                },
                duration: {
                  selected: entry.subscriptionUnit.duration,
                  available: entry.subscriptionUnit.availableDuration,
                  isValid:
                    entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime
                }
              });
              this.entryTotalPrice = entry.totalPrice.formattedValue;
              this.isOneTimeShipment = entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime;
            }
          });
       
          if(!this.isCheckoutProductsTagged) {
            let dataLayer = this.windowRef['dataLayer'] || [];
            let items = this.entryModel.map(entry => ({
                item_id: entry.product.code,              
                item_name: entry.product.name, 
                discount: parseFloat((entry.basePrice.value - entry.totalPrice.value/entry.quantity).toFixed(2)),
                price: parseFloat(entry.basePrice.value.toFixed(2)),
                quantity: entry.quantity,
                item_variant:entry.product.baseOptions[0].selected.size,
            }));
            this.fireViewCartEvent(items);
            if (!this.isMobile) {
              this.beginCheckout();
            }
            this.isCheckoutProductsTagged = true;
          }
        }
      }
    }
  }

  fireViewCartEvent(items) {
    if(!this.isCheckoutProductsTagged) {
      this.isCheckoutProductsTagged = true;
    }

    const ga4Tag = {
      event: 'view_cart',
      ecommerce: {
        value: this.cart.subTotal.value,
        currency : this.cart.subTotal.currencyIso, 
        items
      }
    };
    let dataLayer = this.windowRef['dataLayer'] || [];
    dataLayer.push(ga4Tag);
  }
  beginCheckout() {
    let dataLayer = this.windowRef['dataLayer'] || [];
    let items = this.entryModel.map(entry => ({
        item_id: entry.product.code,              
        item_name: entry.product.name, 
        discount: parseFloat((entry.basePrice.value - entry.totalPrice.value/entry.quantity).toFixed(2)),
        price: parseFloat(entry.basePrice.value.toFixed(2)),
        quantity: entry.quantity,
        item_variant:entry.product.baseOptions[0].selected.size,
    }));
    const ga4TagBeginCheckout = {
      event: 'begin_checkout',
      ecommerce: {
        value: this.cart.subTotal.value,
        currency : this.cart.subTotal.currencyIso, 
        items: items
      }
    };
   
  dataLayer.push(ga4TagBeginCheckout);
  }

  removeDollarSign(formattedValue : string) {
    return formattedValue.replace(/\$|,/g, "");
  }

  onQuantityAdded(quantity: number, entry: Entry) {
    if (quantity <= 99) {
      let _quantity = quantity + 1;
      const editingCart: EditingCart = {
        ...this.getCart(entry),
        quantity: '' + _quantity
      };
      this.submitted.emit({
        entryNumber: entry.entryNumber,
        editingCart: editingCart
      });
  
      let dataLayer = this.windowRef['dataLayer'] || [];
      let discountitem = entry.basePrice.value - entry.totalPrice.value / entry.quantity;
      const ga4Tag = {
        event: 'add_to_cart',
        ecommerce: {
          currency: this.cart.subTotal.currencyIso,
          value: entry.basePrice.value - discountitem,
          items: [{
            item_id: entry.product.code,
            item_name: entry.product.name,
            item_variant: entry.product.baseOptions[0].selected.size,
            discount: parseFloat((entry.basePrice.value - entry.totalPrice.value / entry.quantity).toFixed(2)),
            price: parseFloat(entry.basePrice.value.toFixed(2)),
            quantity: 1
          }],
        }
      };
      dataLayer.push(ga4Tag);
  
      let items = this.entryModel.map(entry => ({
        item_id: entry.product.code,
        item_name: entry.product.name,
        discount: parseFloat((entry.basePrice.value - entry.totalPrice.value / entry.quantity).toFixed(2)),
        price: parseFloat(entry.basePrice.value.toFixed(2)),
        quantity: entry.quantity === quantity ? _quantity : entry.quantity, // Use the updated quantity here
        item_variant: entry.product.baseOptions[0].selected.size,
      }));
  
      // Call fireViewCartEvent with the updated quantity
      setTimeout(() => {
        this.fireViewCartEvent(items);
      }, 3000);
    }
  }

  onQuantityRemoved(quantity: number, entry: Entry) {
    let _quantity = quantity - 1;
    if (_quantity !== 0) {
      const editingCart: EditingCart = {
        ...this.getCart(entry),
        quantity: '' + _quantity
      };
      let dataLayer = this.windowRef['dataLayer'] || [];
      let discountitem = entry.basePrice.value - entry.totalPrice.value / entry.quantity;
      let valueofitemremoved = entry.basePrice.value - discountitem;
      const ga4Tag = {
        event: 'remove_from_cart',
        ecommerce: {
          value: parseFloat(valueofitemremoved.toFixed(2)),
          currency: this.cart.subTotal.currencyIso,
          items: [{
            item_id: entry.product.code,
            item_name: entry.product.name,
            discount: parseFloat((entry.basePrice.value - entry.totalPrice.value / entry.quantity).toFixed(2)),
            price: parseFloat(entry.basePrice.value.toFixed(2)),
            quantity: 1,
            item_variant: entry.product.baseOptions[0].selected.size,
          }],
        }
      };
      dataLayer.push(ga4Tag);
  
      let items = this.entryModel.map(entry => ({
        item_id: entry.product.code,
        item_name: entry.product.name,
        discount: parseFloat((entry.basePrice.value - entry.totalPrice.value / entry.quantity).toFixed(2)),
        price: parseFloat(entry.basePrice.value.toFixed(2)),
        quantity: entry.quantity === quantity ? _quantity : entry.quantity, // Use the updated quantity here
        item_variant: entry.product.baseOptions[0].selected.size,
      }));
  
      // Call fireViewCartEvent with the updated quantity
      setTimeout(() => {
        this.fireViewCartEvent(items);
      }, 3000);
  
      this.submitted.emit({
        entryNumber: entry.entryNumber,
        editingCart: editingCart
      });
    }
  }

  onFrequencyChanged(frequency: DeliveryFrequencies, entry: Entry) {
    const editingCart: EditingCart = {
      ...this.getCart(entry),
      subscriptionUnit: {
        deliveryFrequency: frequency,
        duration:
          frequency !== DeliveryFrequencies.OneTime
            ? entry.subscriptionUnit.duration
            : undefined
      }
    };
    this.submitted.emit({
      entryNumber: entry.entryNumber,
      editingCart: editingCart
    });
  }

  onDurationChanged(duration: DeliveryDurations, entry: Entry) {
    const editingCart: EditingCart = {
      ...this.getCart(entry),
      subscriptionUnit: {
        deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
        duration: duration
      }
    };
    this.submitted.emit({
      entryNumber: entry.entryNumber,
      editingCart: editingCart
    });
  }

  onSubscriptionChange(entrySubscription) {
    let { entry, duration, deliveryFrequency } = entrySubscription;

    const editingCart: EditingCart = {
      ...this.getCart(entry),
      subscriptionUnit: {
        deliveryFrequency: deliveryFrequency,
        duration: deliveryFrequency !== DeliveryFrequencies.OneTime
          ? duration
          : undefined
      }
    };
    this.submitted.emit({
      entryNumber: entry.entryNumber,
      editingCart: editingCart
    });
  }

  getCart(entry: Entry): EditingCart {
    const editingCart: EditingCart = {
      quantity: '' + entry.quantity,
      product: {
        code: entry.product.code
      },
      subscriptionUnit: {
        deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
        duration: entry.subscriptionUnit.duration
      },
      pet: entry.pet,
      duplicateOrdersFlag: entry.duplicateOrdersFlag,
      duplicateOrdersCreationDate: entry.duplicateOrdersCreationDate
    };
    return editingCart;
  }

  openCardInfoModal(templateRefIndex) {

    this.selectedIndex = templateRefIndex;
    // const templateRef = this.cartInfoModal.find((template, index) => index == templateRefIndex);
    // setTimeout(() => {
    //   this.modalService.open(templateRef).result.then(() => { });
    // });
  }

  closeCartInfoModal() {
    this.selectedIndex = null;
  }

  confirmDeleteCartItem(entry: Entry, index) {

    this.modalService.open(this.confirmDeleteCartItemModal).result.then(
      () => {
        this.deleteItem(entry, index);
        let dataLayer = this.windowRef['dataLayer'] || [];
        let discountitem = entry.basePrice.value - entry.totalPrice.value/entry.quantity;
        let valueofitemremoved = (entry.basePrice.value - discountitem) * entry.quantity;
        const ga4Tag = {
          event: 'remove_from_cart',
          ecommerce: {
           value: parseFloat(valueofitemremoved.toFixed(2)),
           currency : this.cart.subTotal.currencyIso, 
            items
          : [{ 
            item_id: entry.product.code,              
            item_name: entry.product.name, 
            discount: parseFloat((entry.basePrice.value - entry.totalPrice.value/entry.quantity).toFixed(2)),
            price: parseFloat(entry.basePrice.value.toFixed(2)),
            quantity: entry.quantity,
            item_variant:entry.product.baseOptions[0].selected.size, 
          }],
          }
        };
       
      dataLayer.push(ga4Tag);
      const items = this.entryModel.map(entry => ({
        item_id: entry.product.code,
        item_name: entry.product.name,
        discount: parseFloat((entry.basePrice.value - entry.totalPrice.value/entry.quantity).toFixed(2)),
        price: parseFloat(entry.basePrice.value.toFixed(2)),
        quantity: entry.quantity,
        item_variant: entry.product.baseOptions[0].selected.size,
      }));
      setTimeout(() => {
        this.fireViewCartEvent(items);
      }, 3000);
      },
      () => {
      }
    );
  }

  deleteItem(entry: Entry, index) {

    this.entryModel.splice(index, 1);
    const editingCart: EditingCart = {
      ...this.getCart(entry),
      quantity: '' + 0
    };
    this.submitted.emit({
      entryNumber: entry.entryNumber,
      editingCart: editingCart
    });

  }

  //Update the quantities with high number
  getQuantitiesFn(max: number) {
    const result = [];
    for (let i = 1; i <= max; i++) {
      result.push(i);
    }
    return result;
  }

  success() {
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(() => {
        this.router.navigate(['/home']);
      }
      );
    });
  }

  placeOrder() {
    setTimeout(() => {
      this.submitOrder.emit();
    });
  }
}