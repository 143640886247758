import {
  LayoutActionTypes,
  LayoutActionsUnion,
} from '../actions/layout.actions';
import { LayoutModes } from '../constants/layout';
import { ScrollChange } from '../models/scrollChange';

export interface State {
  isMobile: boolean;
  layoutMode: LayoutModes;
  scrollChange: ScrollChange;
}

const initialState: State = {
  isMobile: false,
  layoutMode: LayoutModes.Normal,
  scrollChange: null,
};

export function reducer(
  state: State = initialState,
  action: LayoutActionsUnion
): State {
  switch (action.type) {

    case LayoutActionTypes.ChangeMedia: {
      console.log('isMobile:',action.state.matches)
      return {
        ...state,
        isMobile: action.state.matches,
      };
    }

    case LayoutActionTypes.ChangeLayoutMode: {
      return {
        ...state,
        layoutMode: action.layoutMode,
      };
    }

    case LayoutActionTypes.ChangeScroll: {
      return {
        ...state,
        scrollChange: action.scrollChange,
      };
    }

    default:
      return state;
  }
}

export const getIsMobile = (state: State) => state.isMobile;
export const getLayoutMode = (state: State) => state.layoutMode;
export const getScrollChange = (state: State) => state.scrollChange;
