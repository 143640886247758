<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
<ng-template #mobileLayout>
  <div class="app-mobile-layout d-flex flex-column">
    <div class="header-banner">

      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt ps-2"></i>
        </button>
      </div>
      <div class="container pt-4 mt-2">
        <!-- <div class="d-flex align-items-center">
          <i class="app-logo"></i>
        </div> -->
        <div class="text-center">
          <h3 class="ms-5 me-5 text-white text-center">Create your Hill's to Home Account:</h3>
        </div>
      </div>
    <form [formGroup]="registerForm" (ngSubmit)="registerParent()" autocomplete="off" class="m-4">

      <div class="form-group" [class.is-invalid]="registerForm.controls['firstName'] && registerForm.controls['firstName'].invalid">
        <label for="firstName" class="small">First Name</label>
        <input id="firstName" name="firstName" type="text" class="form-control" placeholder="First Name" formControlName="firstName">
      </div>

      <div class="form-group" [class.is-invalid]="registerForm.controls['lastName'].dirty && registerForm.controls['lastName'].invalid">
        <label for="lastName" class="small">Last Name</label>
        <input id="lastName" name="lastName" type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
      </div>

      <div class="form-group" [class.is-invalid]="registerForm.controls['emailAddress'].dirty && registerForm.controls['emailAddress'].invalid">
        <label for="emailAddress" class="small">Email Address</label>
        <span *ngIf="!envCheck" style="color: red; font-size: 0.8rem;">Use a <a style="color: red; font-size: 0.8rem;" href="https://docs.google.com/document/d/18_DyKwiggmDT50H3KjqLP99NAyeXSqeOLIpnujNcsqI/edit" target="_blank">valid email</a> to prevent bounces</span>
        <input id="emailAddress" name="emailAddress" type="email" class="form-control" placeholder="Email" formControlName="emailAddress">
      </div>
      <div
          *ngIf="registerForm.controls['emailAddress'].errors && registerForm.controls['emailAddress'].errors['pattern']"
          class="alert alert-danger small validation-message" >
          Invalid Email Address
        </div>
      <div class="d-flex">
        <div class="form-group me-1 w-60" [class.is-invalid]="registerForm.controls['phoneNumber'].dirty && registerForm.controls['phoneNumber'].invalid">
          <label for="phoneNumber" class="small">Phone Number</label>
          <input id="phoneNumber" name="phoneNumber" type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber">
        </div>
        <div class="form-group w-40 mx-height-70"
             [class.is-invalid]="registerForm.controls['phoneType'].dirty && registerForm.controls['phoneType'].invalid && registerForm.controls['phoneType'].errors && registerForm.controls['phoneType'].errors.defaultVal">
          <label for="phoneType" class="small">Phone Type</label>
          <select id="phoneType"
                  name="phoneType"
                  class="form-control custom-select"
                  formControlName="phoneType">
            <option *ngFor="let ph of phoneTypes" [value]="ph.name">{{ph.name}}</option>
          </select>
        </div>
      </div>
      <div
      *ngIf="registerForm.controls['phoneNumber'].errors && registerForm.controls['phoneNumber'].errors['pattern']"
      class="alert alert-danger small validation-message w-57"  >
      Phone Number should be 123-123-1234 format
    </div>
      <div class="d-flex">
        <div class="form-group me-1 w-60" [class.is-invalid]="registerForm.controls['petName'].dirty && registerForm.controls['petName'].invalid">
          <label for="petName" class="small">Pet Name</label>
          <input id="petName" name="petName" type="text" class="form-control" placeholder="Pet Name" formControlName="petName">
        </div>
        <div class="form-group w-40"
             [class.is-invalid]="registerForm.controls['species'].dirty && registerForm.controls['species'].invalid && registerForm.controls['species'].errors && registerForm.controls['phoneType'].errors.defaultVal">
          <label for="species" class="small">Species</label>
          <select id="species"
                  name="species"
                  class="form-control custom-select"
                  formControlName="species">
            <option *ngFor="let s of species" [value]="s.valueOf()">{{s.valueOf()}}</option>
          </select>
        </div>
      </div>
      <!-- <div class="google-captcha">
        <re-captcha
          name="captcha"
          required
          siteKey="{{siteKey}}"
          formControlName="recaptchaReactive"
          (resolved)="resolved($event)"
          (captchaExpired)="handleCaptchaExpiry()">
      </re-captcha>
      </div> -->
        <div *ngIf="errorMessage" class="alert alert-danger mt-4 small text-center">
          {{errorMessage}}
        </div>
        <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg me-4 mt-4 mb-4" (click)="registerParent()" [disabled]="registerForm.invalid">
          Begin Registration
        </button>
        <div class="redirectToPPlogin">
          <a routerLink="/login">Already have an Account? Sign In</a>
        </div>
    </form>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">
    <div class="container d-flex align-items-start pt-4">
      <div class="home-logo">
        <img src="assets/images/060118_HillsToHomeLogo.png">
      </div>
      <button class="visit-btn mt-3" (click)="openUrl()">
        <span class="w700">VISIT HILLSPET.COM</span>
        <i class="fa fa-external-link-square-alt ps-2"></i>
      </button>
    </div>
    <div class="container pt-3 pb-6">
      <h2 class="text-white">Create your Hill's to Home Account:</h2>
     <div class="d-flex">
        <form [formGroup]="registerForm" (ngSubmit)="registerParent()" class="pt-2 w-35">

          <div class="form-group" [class.is-invalid]="registerForm.controls['firstName'] && registerForm.controls['firstName'].invalid">
            <label for="firstName" class="small">First Name</label>
            <input id="firstName" name="firstName" type="text" class="form-control" placeholder="First Name" formControlName="firstName">
          </div>

          <div class="form-group" [class.is-invalid]="registerForm.controls['lastName'].dirty && registerForm.controls['lastName'].invalid">
            <label for="lastName" class="small">Last Name</label>
            <input id="lastName" name="lastName" type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
          </div>

          <div class="form-group" [class.is-invalid]="registerForm.controls['emailAddress'].dirty && registerForm.controls['emailAddress'].invalid">
            <label for="emailAddress" class="small">Email Address</label>
            <span *ngIf="!envCheck" style="color: red; font-size: 0.8rem;">Use a <a style="color: red; font-size: 0.8rem;" href="https://docs.google.com/document/d/18_DyKwiggmDT50H3KjqLP99NAyeXSqeOLIpnujNcsqI/edit" target="_blank">valid email</a> to prevent bounces</span>
       <input id="emailAddress" name="emailAddress" type="email" class="form-control" placeholder="Email" formControlName="emailAddress">
          </div>
          <div
          *ngIf="registerForm.controls['emailAddress'].errors && registerForm.controls['emailAddress'].errors['pattern']"
          class="alert alert-danger small validation-message" >
          Invalid Email Address
        </div>
          <div class="d-flex">
            <div class="form-group w-60" [class.is-invalid]="registerForm.controls['phoneNumber'].dirty && registerForm.controls['phoneNumber'].invalid">
              <label for="phoneNumber" class="small">Phone Number</label>
              <input id="phoneNumber" name="phoneNumber" type="text" class="form-control" placeholder="Phone No" formControlName="phoneNumber">
            </div>
            <div class="form-group w-40 ms-3 mx-height-70"
                 [class.is-invalid]="registerForm.controls['phoneType'].dirty && registerForm.controls['phoneType'].invalid && registerForm.controls['phoneType'].errors && registerForm.controls['phoneType'].errors.defaultVal">
              <label for="phoneType" class="small">Phone Type</label>
              <select id="phoneType"
                      name="phoneType"
                      class="form-control custom-select"
                      formControlName="phoneType">
                <option *ngFor="let ph of phoneTypes" [value]="ph.name">{{ph.name}}</option>
              </select>
            </div>
          </div>
          <div
          *ngIf="registerForm.controls['phoneNumber'].errors && registerForm.controls['phoneNumber'].errors['pattern']"
          class="alert alert-danger small validation-message w-57" >
          Phone Number should be 123-123-1234 format
        </div>
          <div class="d-flex">
            <div class="form-group w-60" [class.is-invalid]="registerForm.controls['petName'].dirty && registerForm.controls['petName'].invalid">
              <label for="petName" class="small">Pet Name</label>
              <input id="petName" name="petName" type="text" class="form-control" placeholder="Pet Name" formControlName="petName">
            </div>
            <div class="form-group w-40 ms-3"
                 [class.is-invalid]="registerForm.controls['species'].dirty && registerForm.controls['species'].invalid && registerForm.controls['species'].errors && registerForm.controls['species'].errors.defaultVal">
              <label for="species" class="small">Species</label>
              <select id="species"
                      name="species"
                      class="form-control custom-select"
                      formControlName="species">
                <option *ngFor="let s of species" [value]="s.valueOf()">{{s.valueOf()}}</option>
              </select>
            </div>
          </div>
          <!-- <div class="google-captcha">
              <re-captcha
                name="captcha"
                required
                siteKey="{{siteKey}}"
                formControlName="recaptchaReactive"
                (resolved)="resolved($event)"
                (captchaExpired)="handleCaptchaExpiry()">
              </re-captcha>
          </div> -->
        </form>
      </div>
      <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg me-4 mt-2 mb-4" (click)="registerParent()" [disabled]="registerForm.invalid">
          Begin Registration
        </button>
        <div *ngIf="errorMessage" class="alert alert-danger mt-1 small text-center">
            {{errorMessage}}
          </div>
          <div class="redirectToPPlogin">
            <a routerLink="/login">Already have an Account? Sign In</a>
          </div>
    </div>
  </div>
  </div>
</ng-template>
<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
    <div class="modal-body text-center">
      <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
      <p class="m-4 h4 text-center">
        You have been successfully registered!
      </p>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
              (click)="closeSuccess()">
        OK
      </button>
    </div>
</ng-template>
