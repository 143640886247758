<div class="app-mobile-layout">

  <h5 class="app-title text-center m-4">
    My Account
  </h5>

  <div class="d-flex align-items-center m-4">
    <h6 class="mb-0">Edit Name</h6>
  </div>

  <form [formGroup]="profileForm" autocomplete="off" class="m-4 edit-form" (ngSubmit)="save()">
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['firstName'].dirty && profileForm.controls['firstName'].invalid">
      <label for="firstName" class="small">First Name</label>
      <input id="firstName"
             name="firstName"
             type="text"
             class="form-control"
             placeholder="First Name"
             formControlName="firstName">
    </div>
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['lastName'].dirty && profileForm.controls['lastName'].invalid">
      <label for="lastName" class="small">Last Name</label>
      <input id="lastName"
             name="lastName"
             type="text"
             class="form-control"
             placeholder="Last name"
             formControlName="lastName">
    </div>
    <div class="mt-5">
      <button type="submit"
              class="btn btn-primary w-100"
              [disabled]="profileForm.pristine || profileForm.invalid || profileForm.disabled">
        Save
      </button>
    </div>
  </form>

</div>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully changed your name
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
            (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
