<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      My Subscriptions
    </h5>
    <button type="button" class="btn btn-secondary app-outline btn-sm float-end me-3 mb-2" (click)="showInactiveSubscription()">
      {{showInactiveSub ? 'Show All' : 'Hide Inactive Subscriptions'}}
    </button>
    <div class="p-3">
      <table class="table">
        <thead>
          <tr>
            <th class="w-40">Subscription #</th>
            <th class="w-30">Pet</th>
            <th class="w-30">Status
              <span><i class="fa fa-info-circle" style="color: #0054a4;" placement="bottom" [autoClose]="false" [ngbTooltip]="HtmlContent" content-type="template"></i></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-sty" *ngFor="let subscription of subscriptions?.autoShipTemplates" (click)="select(subscription)">
            <td class="text-underline w-40">{{subscription.subscriptionId}}</td>
            <td class="w-30">{{subscription.subscriptionItem?.pet}}</td>
            <td class="app-status w-30">{{subscription.recurringOrderStatus | appOrderStatus}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #ngbPanelDateEmpty>
  <div class="w-20 accordion-item-text">
    {{' Not Applicable '}}
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5 subscriptions-list subsAcc">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="fw-normal m-0">Subscriptions</h4>
        <button type="button" class="btn btn-secondary app-outline btn-sm" (click)="showInactiveSubscription()">
          {{showInactiveSub ? 'Show All' : 'Hide Inactive Subscriptions'}}
        </button>
      </div>
      <div class="d-flex p-4 mt-3">
        <b class="text-uppercase w-20">Subscription #</b>
        <b class="text-uppercase w-20">Pet</b>

        <b class="text-uppercase w-20">Estimated Delivery<span class="sup-star">&#42;</span></b>
        <b class="text-uppercase w-20">Status<span><i class="fa fa-info-circle ms-1 mb-3" style="color:rgb(63, 63, 236)" placement="bottom" [autoClose]="false" [ngbTooltip]="HtmlContent"></i></span></b>
        <b class="text-uppercase w-20">Frequency</b>
        <b class="text-uppercase w-20">Scheduled End Date</b>
      </div>
      <div ngbAccordion [closeOthers]="true" >
        <div *ngFor="let subscription of subscriptions?.autoShipTemplates; let i = index"  ngbAccordionItem [collapsed]="!isPanelOpen(i)" >
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="togglePanel(i)">
              <div class="w-100 d-flex p-4 small app-card-header" [class.selected]="isPanelOpen(i)">
                <div class="w-20 d-flex">
                  <i class="fas fa-angle-right" [class.fa-rotate-90]="isPanelOpen(i)"></i>
                  <span class="subn-styl text-underline ms-3">{{subscription.subscriptionId}}</span>
                </div>
                <div class="w-20 accordion-item-text">
                  {{subscription.subscriptionItem?.pet}}
                </div>
                <div class="w-20 accordion-item-text" *ngIf="subscription.recurringOrderStatus === 'ACTIVE'; else ngbPanelDateEmpty">
                  {{subscription.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}
                </div>
                <div class="w-20 accordion-item-text">
                  {{subscription.recurringOrderStatus | appOrderStatus}}
                </div>
                <div class="w-20 accordion-item-text">
                  {{subscription.subscriptionItem?.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}
                </div>
                <div class="w-20 date-text-center">
                  {{subscription.endDate | date: 'MM/dd/yyyy'}}<span *ngIf="subscription.endDate && !subscription?.npEndDate" class="sup-star">&#42;</span>
                </div>
              </div>
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="p-4 small">
                  <div class="d-flex">
                    <div class="fw-bold text-uppercase w-10"></div>
                    <div class="fw-bold text-uppercase w-40">Item</div>
                    <div class="fw-bold text-uppercase w-10">Started</div>
                    <div class="fw-bold text-uppercase w-10 text-right">Item price</div>
                    <div class="fw-bold text-uppercase w-20 text-center">Quantity</div>
                    <div class="fw-bold text-uppercase w-10 text-right pe-3">Price</div>
                  </div>
                  <hr class="mt-3" />
                  <div *ngFor="let entry of subscriptionDetail?.cart?.entries; let i = index" class="d-flex pt-3">
                    <div class="w-10">
                      <div class="d-flex flex-column align-items-center">
                        <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
                        <div class="app-cart-img text-center" *ngIf="!entry.product.images">
                          <i class="fas fa-image fa-4x"></i>
                        </div>
                        <div class="text-black-50 small mt-3">
                          SKU {{entry.product.code}}
                        </div>
                      </div>
                    </div>
                    <div class="w-40">
                      {{entry.product.name}}
                    </div>
                    <div class="w-10">
                      {{subscriptionDetail?.createdDate | date: 'MM/dd/yyyy'}}
                    </div>
                    <div class="w-10 text-right" *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                      <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                      <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                    </div>
                    <div class="w-10 text-right" *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                      {{entry.basePrice.formattedValue}}
                    </div>
                    <div class="w-20 text-center">
                      {{entry.quantity}}
                    </div>
                    <div class="w-10 text-right pe-3">
                      {{subscriptionDetail?.cart?.totalPrice.formattedValue}}
                    </div>
                  </div>
                  <hr class="mt-3" />
                  <div class="d-flex pt-3" *ngIf="subscriptionDetail?.recurringOrderStatus === OrderStatuses.Active && subscriptionDetail?.cart?.entries[0].product.stock.stockLevelStatus !== 'outOfStock'">
                    <div class="flex-grow-1"></div>
                    <div class="d-flex">
                      <button type="button" class="btn btn-primary btn-sm ms-3 me-9" (click)="openEditSubscription()">
                        Edit Subscription
                      </button>
                      <button type="button" class="btn btn-secondary app-outline btn-sm ms-3 me-9" (click)="openShipNowPopup()" [disabled]="!subscriptionDetail?.enableShipNow" [ngClass]="{ 'disabled': !subscriptionDetail?.enableShipNow }">
                        Ship now
                      </button>
                      <button type="button" *ngIf="subscriptionDetail?.nextSkipDeliveryDate" class="btn btn-secondary app-outline btn-sm ms-3 me-9" (click)="openSkipSubscription()">
                        Skip Next Order
                      </button>
                      <button type="button" *ngIf="subscriptionDetail?.recurringOrderStatus === OrderStatuses.Hold" class="btn btn-secondary app-outline btn-sm ms-3" (click)="showNextSubscriptionDateModal(subscriptionDetail)">
                        Reactivate Subscription
                      </button>
                      <div class="d-flex align-items-center moreOptionMenu">
                        <button class="btn btn-secondary app-outline btn-sm ms-3" *ngIf="subscriptionDetail?.cart?.entries[0].subscriptionUnit?.nutritionPlanId" [routerLink]="['/np-products-change', subscriptionDetail?.cart.b2bUnit, subscriptionDetail?.subscriptionId]">
                          Change Product
                        </button>
                        <button class="btn btn-secondary btn-cancel-subscription app-outline btn-sm ms-3" (click)="openCancelSubscription()">Cancel Subscription</button>
                      </div>
                    </div>
                  </div>
                
                  <div class="d-flex pt-3" *ngIf="subscriptionDetail?.recurringOrderStatus === OrderStatuses.OutOfStock || (subscriptionDetail?.recurringOrderStatus === OrderStatuses.Active && subscriptionDetail?.cart?.entries[0].product.stock.stockLevelStatus === 'outOfStock')">
                    <div class="flex-grow-1"></div>
                    <button type="button" class="btn btn-primary btn-sm ms-3" (click)="openEditSubscription()" [disabled]="true">Edit Subscription</button>
                    <div class="d-flex align-items-center moreOptionMenu">
                      <button class="btn btn-secondary btn-cancel-subscription app-outline btn-sm ms-3" (click)="openCancelSubscription()">Cancel Subscription</button>
                    </div>
                  </div>
                
                  <div class="d-flex justify-content-end pt-2 shipNow-text" *ngIf="subscriptionDetail?.recurringOrderStatus === 'ACTIVE' && !subscriptionDetail?.enableShipNow && (subscriptionDetail?.cart?.entries[0].product.stock.stockLevelStatus) !== 'outOfStock'">
                    Your order is being processed.
                  </div>
                  
                  <div class="d-flex" *ngIf="subscriptionDetail?.recurringOrderStatus === OrderStatuses.OutOfStock || (subscriptionDetail?.recurringOrderStatus === OrderStatuses.Active && subscriptionDetail?.cart?.entries[0].product.stock.stockLevelStatus === 'outOfStock')">
                    <div class="flex-grow-1"></div>
                    <div class="d-flex pt-2 shipNow-text">
                      <div>Unfortunately, this food is temporarily unavailable. When we have it back in stock, we'll reactivate your subscription and send you an email to let you know.</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center pt-3 pb-3 note-orders" *ngIf="subscriptions?.autoShipTemplates != null">
        <p>
          <span class="sup-star">&#42;</span>Note: Please visit <a (click)="getOrders()" title="Orders">Orders</a> to
          view Estimated delivery dates for In progress orders.
          <br/>
          <span class="sup-star">&#42;</span>Note: Legacy subscription that will not renew automatically.
          <br/>
          <span class="sup-star">&#42;</span>*Note: Save up to 35% on all Hill's products.
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editSubscriptionModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Edit Subscription</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissTerms()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body p-4">
    <app-edit-subscription #editSubscription [isMobile]="isMobile" [subscription]="subscriptionDetail"
      (submitted)="onSubmitSubscription($event)">
    </app-edit-subscription>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissTerms()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
      [disabled]="editSubscription.orderForm.pristine || editSubscription.orderForm.invalid || editSubscription.orderForm.disabled"
      (click)="editSubscription.save();">
      Save
    </button>
  </div>
</ng-template>

<ng-template #cancelSubscriptionModal let-close="close" let-dismiss="dismiss">
  <div class="modal-header confirmcancel">
    <div class="header-container flex-grow-1">
      <h4 class="modal-title">Cancel Subscription</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body cancelsubsModal">
    <p *ngFor="let entry of subscriptionDetail?.cart?.entries; let i=index">
      Are you sure you want to cancel subscription for the product <b>"{{entry?.product.name}}"</b>?
    </p>
    <p>You can also <b>skip this order</b>.</p>
    <div class="d-flex justify-content-center mt-4">
      <button type="button"  *ngIf="subscriptionDetail?.nextSkipDeliveryDate" class="btn btn-outline btn-sm ms-3 me-9" (click)="openSkipSubscription()">Skip Next Order</button>
      <button type="button" class="btn btn-danger ms-2" (click)="proceedToCancelSubscription()">Cancel Subscription</button>
    </div>
  </div>
</ng-template>

<ng-template #confirmCancelModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header confirmcancel">
    <div class="header-container">
        <h4 class="modal-title">Subscription Cancellation Reason</h4>
        <button type="button" class="close" aria-label="Close" (click)="checkCancelSubscription(subscriptionDetail)">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>
  <div class="modal-body confirmcancelbody">
    <!-- <p class="m-4 h2 fw-bold" *ngFor="let entry of subscriptionDetail?.cart?.entries; let i=index">
      Are you sure you want to cancel subscription for the product "{{entry?.product.name}}" ?
    </p> -->

    <div>
      <p class="fw-normal">
        Select Reason for Cancellation:
      </p>

      <form [formGroup]="subscriptionCancelForm" autocomplete="off" class="ms-4">
        <div class="custom-control custom-radio mt-3">
          <input type="radio" id="toomuchfood" (change)="changeRadioStatus()" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I have too much food">
          <label class="custom-control-label" for="toomuchfood">
            I have too much food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="soon" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I need this food sooner">
          <label class="custom-control-label" for="soon">
            I need this food sooner
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="service" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="Service issue with earlier delivery">
          <label class="custom-control-label" for="service">
            Service issue with earlier delivery
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="taste" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="My pet didn't like the taste">
          <label class="custom-control-label" for="taste">
            My pet didn't like the taste
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="expensive" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="It's too expensive">
          <label class="custom-control-label" for="expensive">
            It's too expensive
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="diffFood" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I'm changing to a different Hill's food">
          <label class="custom-control-label" for="diffFood">
            I'm changing to a different Hill's food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="diffBrand" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I'm changing to a different brand of food">
          <label class="custom-control-label" for="diffBrand">
            I'm changing to a different brand of food
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="noDelivery" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="I no longer want home delivery">
          <label class="custom-control-label" for="noDelivery">
            I no longer want home delivery
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="petPassedAway" class="custom-control-input"
            name="subscriptionCancelReasonRadio" formControlName="subscriptionCancelReasonRadio"
            value="My pet has passed away">
          <label class="custom-control-label" for="petPassedAway">
            My pet has passed away
          </label>
        </div>

        <div class="custom-control custom-radio mt-3">
          <input type="radio" (change)="changeRadioStatus()" id="other" name="subscriptionCancelReasonRadio"
            class="custom-control-input" formControlName="subscriptionCancelReasonRadio" value="OTHER">
          <label class="custom-control-label" for="other">
            Other
          </label>
        </div>
        <div class="mt-2  flex-column align-items-start me-5">
          <div class="custom-control mt-1 mb-0">
            <textarea id="cancelReason" rows="4" class="form-control border border-dark"
              aria-label="Cancel Reason"
              [hidden]="subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].value!='OTHER'"
              formControlName="cancelReason"></textarea>
          </div>
        </div>

        <!-- <div
          *ngIf="(subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].errors && subscriptionCancelForm.controls['subscriptionCancelReasonRadio'].dirty) || showRadioError"
          class="alert alert-danger mt-4 small text-center">
          Please select at least one option.
        </div> -->
      </form>
    </div>

  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" id="cancelSubscriptionCancel"  (click)="closeCancelSubscription()">
      Cancel
    </button> -->
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" id="cancelSubscription" 
      (click)="checkCancelSubscription(subscriptionDetail)">
      Submit
    </button>
</div>
  <div class="mt-2 mb-4 mx-4 px-3">
    <span class="text-black-50">
      For food-related questions or concerns, contact our Hills to Home Consumer Affairs at <a
      class="text-black-50" href="tel:1-800-445-5777">1-800-445-5777</a>. You may contact Hill's to Home Customer Support at <a
      class="text-black-50" href="tel:1-800-235-6877">1-800-235-6877</a> for all other questions.
    </span>
  </div>
</ng-template>

<!-- Subscription Status Tooltip Content -->
<ng-template #HtmlContent>
  <div class="text-start"><span class="fw-bolder">Active: </span><span class="font-weight-light"> Your Subscription is active.</span></div><br>
  <div class="text-start"><span class="fw-bolder">Failed: </span><span class="font-weight-light"> Make sure your address and payment method are up to date.</span></div><br>
  <!-- Tooltip text change and action button updates based on subscription status -   -->
  <div class="text-start"><span class="fw-bolder">Completed: </span><span class="font-weight-light"> The time set for your subscription has ended. Create a new subscription, or if your nutrition plan has expired, contact your vet.</span></div><br>
  <!-- End -->
  <div class="text-start"><span class="fw-bolder">Temporarily Unavailable: </span><span class="font-weight-light">As soon as your product is available, your subscription will be active, and we can begin shipping it to you.</span></div><br>
  <div class="text-start"><span class="fw-bolder">Cancelled: </span><span class="font-weight-light">Your subscription has been cancelled, if you would like to order again, please click SHOP or contact your vet.</span></div>
</ng-template>

<ng-template #confirmHoldModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <p class="m-4 h4 fw-normal">
      Are you sure you want to put subscription #{{subscriptionDetail?.subscriptionId}} on hold?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeConfirm()">
      I'm Sure
    </button>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your subscription
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #confirmEditSubscriptionModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your subscription
    </p>
    <p class="m-4 h4 text-center" *ngIf="subscriptionDetail?.nextScheduledDeliveryDate">
      Your next estimated delivery date is {{subscriptionDetail?.nextScheduledDeliveryDate | date:'MM/dd/yyyy'}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
            (click)="closeSuccess()">
      Ok
    </button>
  </div>
</ng-template>

<ng-template #confirmReactivatelModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <div class="modal-header">
      <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Select Estimated Next Delivery Date</h4>
      </div>
      <button type="button" class="close mt-2" aria-label="Close" (click)="dismissConfirm()">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <form [formGroup]="nextDeliveryDateSelectionform" autocomplete="off" class="mt-2 p-2 bg-white text-body">
      <div class="delivery-radio-list">
     
      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio" id="ship_now" class="custom-control-input" name="NextDeliveryDateSelectionRadio"
          formControlName="NextDeliveryDateSelectionRadio" value="Week_0" (change)="checkValue()">
        <label class="custom-control-label" for="ship_now">
          Deliver now
        </label>
      </div>
      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio" id="delay_1" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
          formControlName="NextDeliveryDateSelectionRadio" value="WEEK_1" (change)="checkValue()">
        <label class="custom-control-label" for="delay_1">
          Deliver one week from today
        </label>
      </div>
      <div class="custom-control custom-radio mt-3  px-2">
        <input type="radio" id="delay_2" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
          formControlName="NextDeliveryDateSelectionRadio" value="WEEK_2" (change)="checkValue()">
        <label class="custom-control-label" for="delay_2">
          Deliver two weeks from today
        </label>
      </div>
      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio" id="delay_3" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
          formControlName="NextDeliveryDateSelectionRadio" value="WEEK_3" (change)="checkValue()">
        <label class="custom-control-label" for="delay_3">
          Deliver three weeks from today
        </label>
      </div>

      <div class="custom-control custom-radio mt-3 px-2">
        <input type="radio" id="delay_custom" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
          formControlName="NextDeliveryDateSelectionRadio" value="WEEK_n" (change)="checkValue()">
        <label class="custom-control-label" for="delay_custom">
          Choose custom date:
        </label>
      </div>
      <!--------->
      <div class="custom-control mt-3 px-2">
        <div class="mt-1 mb-0 custom-box"
          [class.is-invalid]="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty && nextDeliveryDateSelectionform.controls['deliveryDate'].invalid">
          <input [id]="'deliveryDate_'+i" type="date" class="form-control" formControlName="deliveryDate"
            min="{{maxDate}}" onkeydown="return false">
        </div>
        <ul *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty &&
        nextDeliveryDateSelectionform.controls['deliveryDate'].errors &&
                   (nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] ||
                   nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])"
          class="list-unstyled app-invalid-feedback small mt-2 mb-0">
          <li
            *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays']">
            Please select a date on or after {{maxDate}}
          </li>
          <li
            *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])">
            Please select a date on or after {{maxDate}}
          </li>
          <li
            *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'])">
            Please select week days only
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>

      </div>
      <!------->
      <div
        *ngIf="(nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].errors && nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].dirty) || showRadioError"
        class="alert alert-danger mt-4 small text-center">
        Please select atleast one option.
      </div>

      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
      (click)="reactivateSubscription(subscriptionDetail);">
      Reactivate
    </button>
  </div>
</ng-template>
<!-- Start Skip Subscription modal-->
<ng-template #skipSubscriptionModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body skip-subscription-modal">
    <p class="m-4 h2 fw-bold">
      Skip Next Order
    </p>
    
    <div>
      <hr class="mt-1" />
      <div *ngFor="let entry of subscriptionDetail?.cart?.entries; let i=index" class="d-flex pt-3">
        <div class="w-20">
          <div class="d-flex flex-column align-items-center">
            <img src="{{entry.product.images | appImg}}" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
            <div class="app-cart-img text-center" *ngIf="!entry.product.images">
              <i class="fas fa-image fa-4x"></i>
            </div>
          </div>
        </div>
        <div class="w-70">
          {{entry.product.name}}
        </div>
      </div>
    </div>
    <p class="m-4 h5 text-black-50 fw-bold">
      Your next estimated delivery will be {{subscriptionDetail?.nextSkipDeliveryDate | date: 'MM/dd/yyyy'}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" id="skipOrderCancel" (click)="closeSkipSubscription()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" id="skipOrder" (click)="confirmSkipSubscription(subscriptionDetail)">
      I'm Sure
    </button>
  </div>
</ng-template>
<!-- END Skip Subscription modal-->

<!-- Start ship now modal-->
<ng-template #shipNowModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Ship Now</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeShipNowPopup()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body skip-subscription-modal p-4">
    <div>
      <div *ngFor="let entry of subscriptionDetail?.cart?.entries; let i=index" class="d-flex p-4 bg-white text-body">
        <div class="w-30">
          <div class="d-flex flex-column align-items-center">
            <img src="{{entry.product.images | appImg}}" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
            <div class="app-cart-img text-center" *ngIf="!entry.product.images">
              <i class="fas fa-image fa-4x"></i>
            </div>
            <div class="text-black-50 small mt-3">
              SKU {{entry.product.code}}
            </div>
          </div>
        </div>
        <div class="w-70 ps-3">
          <div>{{entry.product.name}}</div>
          <div class="d-flex justify-content-between mt-3">
            <div class="d-flex align-items-center justify-content-between flex-grow-1">
              <div>
                <div class="fw-bold">Price</div>
                <div *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                  <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                  <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                </div>
                <div *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                  {{entry.basePrice.formattedValue}}
                </div>
              </div>
              <div >
                <div class="fw-bold">Subtotal</div>
                <span>{{subscriptionDetail?.cart?.totalPrice.formattedValue}}</span>
              </div>
              <div class="me-2">
                <div class="fw-bold">Quantity</div>
                <span>{{entry.quantity}}</span>
              </div>
            </div>
          </div>
          <hr class="mt-3">
          <div class="pt-2">Your last scheduled delivery was {{subscriptionDetail?.nextScheduledDeliveryDate | date: 'MM/dd/yyyy'}}.</div>
          <div class="pt-2">If you Ship Now, your next estimated delivery will be <span class="fw-bold">{{subscriptionDetail?.nextShipNowDeliveryDate | date: 'MM/dd/yyyy'}}</span>.</div>
          <div class="pt-5 pb-5 shipNow-popup-text">Are you sure you want to ship now?</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="closeShipNowPopup()">
      No
    </button>
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="confirmShipNowSubscription(subscriptionDetail)">
      Yes, Ship Now
    </button>
  </div>
</ng-template>
<!-- End ship now modal-->
