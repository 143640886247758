import {
  Component, EventEmitter, Inject, Input, OnChanges, OnInit,
  Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  Profile,
  Cart,
  Session
} from '../../../api';
import { Authenticate } from '../../../auth/models/authenticate';
import { LayoutModes } from '../../constants/layout';
import { fade, flyInOut, flyOutIn } from '../../../shared/animations/common.animations';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fade, flyInOut, flyOutIn]
})
export class MainComponent implements OnInit, OnChanges {

  menuModalRef: NgbModalRef;

  LayoutModes = LayoutModes;

  loaderVisible = true;

  @Input() layoutMode: LayoutModes = LayoutModes.Normal;

  @Input() back: string | null;

  @Input() isMobile: boolean | false;

  @Input() isstripeNotLinkedMobile: boolean | false;

  @Input() loading: boolean | false;

  @Input() title = '';

  @Input() isLoggedIn: boolean | false;

  @Input() profile: Profile | null;

  @Input() session: Session | null;

  @Input() cart: Cart | null;

  @Input() authenticate: Authenticate | null;

  @Output() loggedOut = new EventEmitter();

  @Output() navigatedBack = new EventEmitter();

  @ViewChild('menuModal', { static: true })
  menuModal: any;

  @ViewChild('confirmUpdateModal', { static: true })
  confirmUpdateModal: any;

  timedOut = false;
  lastPing?: Date = null;
  refreshSession = null;
  sessionToken = '';
  sessionFlag: boolean = false;
  sessionCount: number = 0;
  expiredTime: number = 0;
  openFooterAboutAccordian: boolean = false;
  openFooterHelpAccordian: boolean = false;
  isCartEmpty: boolean = false;

  constructor(private modalService: NgbModal,
    private updates: SwUpdate,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private idle: Idle,
    private keepalive: Keepalive,
    private location: Location) {
    idle.setIdle(1610);
    // sets a timeout period of 10 seconds. after 1610 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      if(this.isLoggedIn == true) {
        this.logout();
      }
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }

  ngOnInit() {
    // Check for updates, if there are prompt
    this.updates.versionUpdates.subscribe(event => {
      if(event.type === 'VERSION_READY') {
        this.modalService.open(this.confirmUpdateModal).result.then(
          () => {
            this.updates.activateUpdate().then(() => this.document.location.reload());
          },
          () => {
          }
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const loadingChange: SimpleChange = changes['loading'];
    if (loadingChange && !loadingChange.firstChange) {
      if (loadingChange.currentValue) {
        this.showLoader();
      } else {
        this.hideLoader();
      }
    }
    // On desktop close any open menu dialogs
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      !isMobileChange.currentValue) {
      if (this.menuModalRef) {
        this.menuModalRef.close();
      }
    }

    let urlValue = this.router.url;
    if (urlValue === '/stripeNotLinked') {
      this.isstripeNotLinkedMobile = true;
    }
    this.reset();
  }

  logoRoute() {
    const terms: RegExp = /terms/ig;
    const path = this.location.path();
    if(!path.match(terms)) {
      this.router.navigate(["/pets/shop"])
    } 
  }

  logout() {
    sessionStorage.removeItem('ottoCustomer');
    sessionStorage.removeItem('ottoStatus');
    this.loggedOut.emit();
  }

  showLoader() {
    this.loaderVisible = true;
  }

  hideLoader() {
    this.loaderVisible = false;
  }

  openMenu() {
    this.menuModalRef = this.modalService.open(this.menuModal, {
      windowClass: 'app-menu-window'
    });
  }

  goBack() {
    this.navigatedBack.emit();
    // this.router.navigate([this.back]);
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  toggleFooterAboutAccordian() {
    this.openFooterAboutAccordian = !this.openFooterAboutAccordian;
  }

  toggleFooterHelpAccordian() {
    this.openFooterHelpAccordian = !this.openFooterHelpAccordian;
  }

  openToolTip() {
    if (!this.cart) {
      this.isCartEmpty = true;
      setTimeout(() => this.isCartEmpty = false, 3000);
    }
  }
}