<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg" *ngIf="!showPetProfile">

    <div class="container pt-3 pb-4">
      <app-edit-order-delivery-page></app-edit-order-delivery-page>
      <app-promotions [promoOn]="'homePage'" [isMobile]="isMobile" [onShopPage]="!showPetProfile"></app-promotions>

      <div class="title-wrapper d-flex align-items-center" *ngIf="pets && pets.length">
        <h2 class="main-title pt-2">Select a Pet to Shop for</h2>
      </div>

      <div class="row justify-content-center mt-1" *ngIf="pets && pets.length">
        <a [routerLink]="['/pets/shop', pet.petCode]" class="text-sty col-sm-12 col-md-4 pet-card"
          *ngFor="let pet of pets; let i = index;">
          <div class="d-flex flex-row align-items-center justify-content-left pet-card-data mt-1 mb-2 ps-4">
            <figure class="mb-0 me-3">
              <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName || 'Pet Image'}}" />
            </figure>
            <h5 class="px-3">{{pet?.petName}}</h5>
          </div>
        </a>
        <div class="col-12 pb-2">
          <a [routerLink]="['/pets/profile']"
            class="btn-blue p-3 d-flex align-items-center justify-content-center pet-card mt-4">
            Manage Pet Profiles
          </a>
        </div>
      </div>
      <div *ngIf="pets && pets?.length == 0" class="mt-4">
        <h3 class="shop-for-pets fw-normal text-center" >
          No active pets found, start by adding a new pet. 
        </h3>
        <div class="m-4 d-flex justify-content-center">
          <button class="btn btn-primary btn-md w-40" [routerLink]="['/add-pet']">
                + Add Pet
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="app-mobile-layout light-grey-bg" *ngIf="showPetProfile">
    <div class="container pt-3 pb-4">

      <div class="title-wrapper d-flex align-items-center pt-1">
        <h2 class="main-title pt-2">My Pets</h2>
        <a [routerLink]="['/add-pet']" class="text-sty bordered-btn-white" *ngIf="pets && pets.length">Add Pet</a>
      </div>
      <div class="row justify-content-center mt-1" *ngIf="pets && pets.length">
        <a [routerLink]="['/pets/profile', pet.petCode]" class="text-sty col-sm-12 col-md-4 pet-card"
          *ngFor="let pet of pets; let i = index;">
          <div class="d-flex flex-row align-items-center justify-content-left pet-card-data mt-1 mb-2 ps-4">
            <figure class="mb-0 me-3">
              <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName || 'Pet Image'}}" />
            </figure>
            <h5>{{pet?.petName}}</h5>
          </div>
        </a>
        <div class="col-12 pb-2">
          <a [routerLink]="['/add-pet']"
            class="btn-blue p-3 d-flex align-items-center justify-content-center pet-card mt-4">
            Add Pet
          </a>
        </div>
      </div>
      <div *ngIf="pets && pets?.length == 0" class="mt-4">
        <h3 class="shop-for-pets fw-normal text-center" >
          No active pets found, start by adding a new pet. 
        </h3>
        <div class="m-4 d-flex justify-content-center">
          <button class="btn btn-primary btn-md w-40" [routerLink]="['/add-pet']">
                + Add Pet
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg" *ngIf="!showPetProfile">
    <div class="container pt-5 padding-bottom-grey">
      <!-- container1024 -->
      <app-promotions [promoOn]="'homePage'"></app-promotions>
      <div *ngIf="pets && pets?.length">
        <h2 class="main-title text-center pt-3">Select a Pet to Shop for </h2>
        <p class="title-tagline pb-3">#1 Vet Recommended brand brought right to your door for free</p>
        <div class="mx-1">
          <div class="row justify-content-center mt-3 " *ngIf="pets && pets.length">
            <a [routerLink]="['/pets/shop', pet.petCode]" class="text-sty col-sm-12 col-md-4 pet-card"
              *ngFor="let pet of pets; let i = index;">
              <div class="d-flex flex-column align-items-center justify-content-center pet-card-data mt-2 mb-3">
                <figure class="mb-3">
                  <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName || 'Pet Image'}}" />
                </figure>
                <h5 class="px-3">{{pet?.petName}}</h5>
              </div>
            </a>
            <div class="col-12 mt-3 mb-2">
              <a [routerLink]="['/pets/profile']"
                class="btn-blue p-3 d-flex align-items-center justify-content-center pet-card mt-4">
                Manage Pet Profiles
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="pets && pets?.length == 0" class="mt-5">
        <h3 class="shop-for-pets fw-normal text-center" >
          No active pets found, start by adding a new pet. 
        </h3>
        <div class="m-4 d-flex justify-content-center">
          <button class="btn btn-primary btn-md w-30" [routerLink]="['/add-pet']">
                + Add Pet
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="app-full-layout light-grey-bg" *ngIf="showPetProfile">

    <div class="container pt-5 padding-bottom-grey">
      <!-- container1024 -->
      <h2 class="main-title text-center pt-0 pb-3">My Pets</h2>
      <div class="mx-1" *ngIf="pets && pets.length">
        <div class="row justify-content-center mt-3">
          <a [routerLink]="['/pets/profile', pet.petCode]" class="text-sty col-md-4 pet-card"
            *ngFor="let pet of pets; let i = index;">
            <div class="d-flex flex-column align-items-center justify-content-center pet-card-data mt-2 mb-3">
              <figure class="mb-3">
                <img src="{{pet | appPetImg}}" class="app-pet-img" alt="{{pet?.petName || 'Pet Image'}}" />
              </figure>
              <h5 class="px-3">{{pet?.petName}}</h5>
            </div>
          </a>
          <div class="col-12 mt-2">

            <a [routerLink]="['/add-pet']"
              class="btn-blue p-3 d-flex align-items-center justify-content-center pet-card mt-4">
              Add Pet
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="pets && pets?.length == 0" class="mt-4">
        <h3 class="shop-for-pets fw-normal text-center" >
          No active pets found, start by adding a new pet. 
        </h3>
        <div class="m-4 d-flex justify-content-center">
          <button class="btn btn-primary btn-md w-30" [routerLink]="['/add-pet']">
                + Add Pet
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Start whats new modal-->
<ng-template #whatsNewModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body whats-new-modal">
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
    <div class="carousel slide">
      <app-whats-new [isMobile]="isMobile" [whatsNewContent]="whatsNewContent" (gotItClicked)="closeModal()"></app-whats-new>
    </div>
  </div>
  
</ng-template>
<!-- End ship now modal-->

