<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      My Pets&#47;Shop
    </h5>
    <div class="d-flex align-items-center m-4">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">{{nutritionPlan?.pet?.petName}} Profile</h6>
      </div>
    </div>
    <h3 class="mt-4 ms-4 me-4 fw-normal">
      {{nutritionPlan?.pet?.petName}}&#39;s {{nutritionPlan?.planName}}
    </h3>
    <div class="p-3">
      <table class="table">
        <thead>
        <tr>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{nutritionPlan?.startDate | date: 'MM/dd/yyyy'}}</td>
          <td>{{nutritionPlan?.endDate | date: 'MM/dd/yyyy'}}</td>
          <td class="text-capitalize"
              [class.text-success]="nutritionPlan?.status === PetStatuses.Active"
              [class.text-danger]="nutritionPlan?.status !== PetStatuses.Active">
            {{nutritionPlan?.status}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="p-3">
      <div class="d-flex">
        <div class="font-weight-bold w-30 flex-shrink-0">Plan Name:</div>
        <div class="flex-grow-1 ms-2">{{nutritionPlan?.planName}}</div>
      </div>
      <div class="d-flex">
        <div class="font-weight-bold w-30 flex-shrink-0">Product Lines:</div>
        <div class="flex-grow-1 ms-2">
          <span *ngFor="let pl of nutritionPlan?.productLines; let i = index">
            {{pl.name}}
            <span *ngIf="i < nutritionPlan?.productLines.length - 1">, </span>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <div class="font-weight-bold w-30 flex-shrink-0">Plan Length:</div>
        <div class="flex-grow-1 ms-2">
          {{nutritionPlan?.planLength | appPlanLength}}
        </div>
      </div>
      <div class="d-flex">
        <div class="font-weight-bold w-30 flex-shrink-0">Plan Dates:</div>
        <div class="flex-grow-1 ms-2">
          {{nutritionPlan?.startDate | date: 'MM/dd/yyyy'}} -
          {{nutritionPlan?.endDate | date: 'MM/dd/yyyy'}}
        </div>
      </div>
    </div>
    <div class="p-3 mt-3">
      <b>Vet Notes: </b> <span>{{nutritionPlan?.notes}}</span>
    </div>
    <div class="p-3 mt-5 mb-5">
      <a [routerLink]="['/np-products', nutritionPlan?.clinic.uid]"
         class="btn btn-primary btn-lg font-weight-light w-100"
         *ngIf="nutritionPlan?.status == PetStatuses.Active">
        Shop Products for this Plan
      </a>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
  </div>
</ng-template>
