<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout d-flex flex-column">
    <div class="header-banner">

      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        <button class="visit-btn mt-3" routerLink="/login">
          <span class="w700">Sign In</span>
            <i class="fas fa-user ps-2"></i>
        </button>
      </div>

      <div class="container pt-4 mt-2">
        <div class="text-center">
          <h1 class="ms-1 me-1 text-white">Forgot Password</h1>
          <p class="ms-5 me-5 text-white">Enter the email you use for Hill's to Home and we'll help you create a new
            password.</p>
        </div>
        <form [formGroup]="authForm" autocomplete="off" class="m-4" (ngSubmit)="resetPassword()">
          <div class="form-group"
            [class.is-invalid]="authForm.controls['email'].dirty && authForm.controls['email'].invalid">
            <label for="email" class="small">Email</label>
            <input id="email" name="email" type="email" class="form-control" placeholder="Email"
              formControlName="email">
          </div>
          <ul *ngIf="authForm.controls['email'].dirty &&
                       authForm.controls['email'].errors && authForm.controls['email'].errors['pattern']"
            class="list-unstyled app-invalid-feedback alternate small">
            <li *ngIf="authForm.controls['email'].errors['pattern']">
              Email not valid
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>
          <div *ngIf="error" class="alert alert-danger mt-4 small text-center">
            {{error}}
          </div>
          <div class="me-2 ms-2 mt-4">
            <button type="submit" class="btn btn-primary w-100" [disabled]="authForm.pristine || authForm.invalid">
              Reset Password
            </button>
          </div>
        </form>

      </div>
    </div>

  </div>

</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">

      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        <button class="visit-btn mt-3 me-2" routerLink="/login">
          <span class="w700">Sign In</span>
          <i class="fas fa-user ps-2"></i>
        </button>
      </div>

      <div class="container pt-4 mt-2">
        <h2 class="text-white">Forgot Password</h2>
        <p class="text-white">Enter the email you use for Hill's to Home and we'll help you create a new
          password.</p>
        <div class="d-flex">
          <form [formGroup]="authForm" class="pt-4 w-30" (ngSubmit)="resetPassword()">
            <div class="form-group"
              [class.is-invalid]="authForm.controls['email'].dirty && authForm.controls['email'].invalid">
              <label for="email" class="small">Email Address</label>
              <input id="email" name="email" type="email" class="form-control" placeholder="Email"
                formControlName="email">
            </div>
            <ul *ngIf="authForm.controls['email'].dirty &&
                       authForm.controls['email'].errors && authForm.controls['email'].errors['pattern']"
              class="list-unstyled app-invalid-feedback small">
              <li *ngIf="authForm.controls['email'].errors['pattern']">
                Email not valid
              </li>
              <i class="app-bottom-arrow"></i>
            </ul>
          </form>
          <div class="flex-grow-1 d-flex flex-column align-self-center">
          </div>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-4 small text-center">
          {{error}}
        </div>
        <div>
          <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg me-4 mt-4 mb-4"
            [disabled]="authForm.pristine || authForm.invalid" (click)="resetPassword()">
            Reset Password
          </button>
        </div>




        <!-- <div class="text-left text-white fw-normal ps-2 pe-2 ">If you'd like to try Hill's to Home, please
              contact your veterinarian to get started.</div> -->
        <!-- <div class="d-inline-block mt-6 pb-2 ps-2 pe-2">
              <div class="text-left text-white fw-normal ps-2 pe-2 ">If you are a veterinarian interested in
                offering Hill's to Home to your clients,<br> please visit vet.hillstohome.com to register or contact your
                Hill's Representative for more information.
              </div>
            </div> -->
      </div>
    </div>


    <!-- <div class="container pt-6 pb-6">
      
    </div> -->
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      <!--We have sent password reset instructions to your email-->
      If you have an account with Hill's to Home, you will receive an email to reset your password soon.
    </p>
    <p class="small">
      <!--Questions? Contact Hill's support at-->
      If you continue to have any problems, please reach out to us at<br>
      <a href="mailto:hillstohome@hillspet.com" target="_top" class="text-underline"><span class="text-underline">hillstohome&#64;hillspet.com</span>
      </a>
      <br>
      or
      <br>
      <a href="tel:+1-800-235-6877" target="_top" class="text-underline"><span class="text-underline">1-800-235-6877</span>
      </a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      Return to Login
    </button>
  </div>
</ng-template>